import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  BooleanField,
  TagField,
  DeleteButton,
} from "@refinedev/antd";
import { Table, Space } from "antd";

export const ProgramList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { data: exercisesData, isLoading: exercisesIsLoading } = useMany({
    resource: "exercise",
    ids: [].concat(
      ...(tableProps?.dataSource?.map((item) => item?.exercises) ?? [])
    ),
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column
          dataIndex={["goal_rpe"]}
          title="Goal Rpe"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={["predefined"]}
          title="Predefined"
          render={(value: any) => <BooleanField value={value} />}
        />
        <Table.Column
          dataIndex="training_days_per_week"
          title="Training Days Per Week"
        />
        <Table.Column
          dataIndex="exercises"
          title="Exercises"
          render={(value: any[]) =>
            exercisesIsLoading ? (
              <>Loading...</>
            ) : (
              <>
                {value?.map((item, index) => (
                  <TagField key={index} value={item} />
                ))}
              </>
            )
          }
        />
        <Table.Column
          dataIndex={["created_at"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <DeleteButton hideText size="small" recordItemId={record.id} />
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
