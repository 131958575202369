import React, { useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Button, Card, Form, Input, InputNumber, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export const SplitDayCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: mainMuscleSelectProps } = useSelect({
    resource: "mainMuscle",
    optionLabel: "name",
    optionValue: "id",
  });

  const [selectedMainMuscle, setSelectedMainMuscle] = useState<number | null>(
    null
  );

  const handleMainMuscleChange = (value: any) => {
    setSelectedMainMuscle(value);
  };
  console.log("selectedMainMuscle: ", selectedMainMuscle);
  const { selectProps: primaryMuscleSelectProps } = useSelect({
    resource: "primaryMuscle",
    optionLabel: "name",
    optionValue: "id",
    filters: selectedMainMuscle
      ? [{ field: "mainMuscleId", operator: "eq", value: selectedMainMuscle }]
      : [],
  });

  const { selectProps: exerciseTypeSelectProps } = useSelect({
    resource: "exerciseType",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: primaryMuscleSelectProps2 } = useSelect({
    resource: "primaryMuscle",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          name={["dayName"]}
          rules={[
            {
              required: true,
              message: "Day Name is required",
            },
          ]}
        >
          <Input placeholder={"Day Name"} />
        </Form.Item>
        <Form.Item name={["displayName"]}>
          <Input placeholder={"Display Name"} />
        </Form.Item>
        <Form.Item name={["muscleEmphasisId"]}>
          <Select
            placeholder="Select Muscle Emphasis"
            {...primaryMuscleSelectProps2}
            allowClear
            onSearch={undefined}
            filterOption={true}
            optionFilterProp={"label"}
          />
        </Form.Item>
        <Form.List name={["exercises"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Card
                  key={key}
                  title={`Exercise ${name + 1}`}
                  bordered={true}
                  style={{ marginTop: "16px", marginBottom: "10px" }}
                >
                  <Form.Item
                    {...restField}
                    name={[name, "noOfSets"]}
                    fieldKey={[fieldKey!, "noOfSets"]}
                    rules={[
                      { required: true, message: "Number of Sets is required" },
                    ]}
                    initialValue={3} // Set default value
                    label="Number of Sets" // Add label
                  >
                    <InputNumber
                      placeholder={"Number of Sets"}
                      style={{ width: "100%" }} // Ensuring the InputNumber covers full width
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "mainMuscleId"]}
                    fieldKey={[fieldKey!, "mainMuscleId"]}
                    rules={[
                      { required: true, message: "Main Muscle is required" },
                    ]}
                  >
                    <Select
                      placeholder="Select Main Muscle"
                      {...mainMuscleSelectProps}
                      onChange={(value) => {
                        handleMainMuscleChange(value);
                        formProps.form!.setFieldValue(
                          ["exercises", name, "secondaryMuscles"],
                          []
                        ); // Reset secondary muscles on main muscle change
                      }}
                      onSearch={undefined}
                      filterOption={true}
                      optionFilterProp={"label"}
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "secondaryMuscles"]}
                    fieldKey={[fieldKey!, "secondaryMuscles"]}
                    rules={[
                      {
                        validator: (_, value) =>
                          selectedMainMuscle
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Select Main Muscle first")
                              ),
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Primary Muscles"
                      {...primaryMuscleSelectProps}
                      disabled={!selectedMainMuscle} // Disable if main muscle is not selected
                      onSearch={undefined}
                      filterOption={true}
                      optionFilterProp={"label"}
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "exerciseTypeId"]}
                    fieldKey={[fieldKey!, "exerciseTypeId"]}
                  >
                    <Select
                      allowClear
                      placeholder="Select Exercise Type"
                      {...exerciseTypeSelectProps}
                      onSearch={undefined}
                      filterOption={true}
                      optionFilterProp={"label"}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "muscleEmphasisId"]}
                    fieldKey={[fieldKey!, "muscleEmphasisId"]}
                  >
                    <Select
                      allowClear
                      placeholder="Select Muscle Emphasis"
                      {...mainMuscleSelectProps}
                      onSearch={undefined}
                      filterOption={true}
                      optionFilterProp={"label"}
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Card>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Exercise
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Create>
  );
};
