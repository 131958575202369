import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { DateField, NumberField, Show, TextField } from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const RecipeShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading} canDelete={true} canEdit={true}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Serves</Title>
      <TextField value={record?.serves ?? ""} />
      <Title level={5}>Number Of Servings</Title>
      <NumberField value={record?.number_of_servings ?? ""} />
      <Title level={5}>Type</Title>
      <TextField value={record?.type} />
      <Title level={5}>Calories</Title>
      <NumberField value={record?.calories ?? ""} />
      <Title level={5}>Protein</Title>
      <NumberField value={record?.protein ?? ""} />
      <Title level={5}>Fat</Title>
      <NumberField value={record?.fat ?? ""} />
      <Title level={5}>Carbs</Title>
      <NumberField value={record?.carbs ?? ""} />
      <Title level={5}>Type</Title>
      <NumberField value={record?.recipe_type ?? ""} />
      <Title level={5}>Preparation Time</Title>
      <TextField value={record?.preparation_time ?? ""} />
      <Title level={5}>Instructions</Title>
      <TextField value={record?.instructions} />
      <Title level={5}>Image</Title>
      <TextField value={record?.image} />
      <Title level={5}>Ingredients</Title>
      {record?.ingredients.map((ingredient: any) => (
        <div key={ingredient.id}>
          {ingredient.food.name} - {ingredient.quantity}{" "}
          {ingredient.unit === "NONE" ? "" : ingredient.unit}
        </div>
      ))}
      <Title level={5}>Created At</Title>
      <DateField value={record?.created_at} />
    </Show>
  );
};
