import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TagField,
    TextField,
    DateField,
} from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const BlockShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading} canDelete={true}>
            <Title level={5}>Id</Title>
            <NumberField value={record?.id ?? ""} />
            <Title level={5}>Name</Title>
            <TextField value={record?.name} />
            <Title level={5}>Experience Level</Title>
            <TextField value={record?.experience_level} />
            <Title level={5}>Block Length In Weeks</Title>
            <NumberField value={record?.block_length_in_weeks ?? ""} />
            <Title level={5}>Workout Days Per Week</Title>
            <NumberField value={record?.workout_days_per_week ?? ""} />
            <Title level={5}>Created At</Title>
            <DateField value={record?.created_at} />
        </Show>
    );
};
