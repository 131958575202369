import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import { Button, Form, Input, InputNumber, Select, Space, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

export const RecipeCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: foodSelectProps } = useSelect({
    resource: "food",
    optionLabel: "name",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Serves"
          name={["serves"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Number Of Servings"
          name={["number_of_servings"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Type" name={["type"]} rules={[{ required: true }]}>
          <Select defaultValue={"LUNCH"}>
            <Select.Option value="BREAKFAST">Breakfast</Select.Option>
            <Select.Option value="LUNCH">Lunch</Select.Option>
            <Select.Option value="DINNER">Dinner</Select.Option>
            <Select.Option value="SNACK">Snack</Select.Option>
            <Select.Option value="PREWORKOUT">Pre-Workout</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Calories"
          name={["calories"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Protein"
          name={["protein"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Fat"
          name={["fat"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Carbs"
          name={["carbs"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Preparation Time"
          name={["preparation_time"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
            label="Recipe Type"
            name={["recipe_type"]}
            rules={[
              {
                required: true,
              },
            ]}
        >
          <Select>
            <Select.Option value="NONE">None</Select.Option>
            <Select.Option value="BREAKFAST">Breakfast</Select.Option>
            <Select.Option value="LUNCH">Lunch</Select.Option>
            <Select.Option value="SNACK">Snack</Select.Option>
            <Select.Option value="PREWORKOUT">Pre-Workout</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Instructions"
          name={["instructions"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Image">
          <Form.Item
            name={"image"}
            valuePropName={"fileList"}
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              listType="picture-card"
              action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=recipeImages`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              maxCount={1}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Ingredients">
          <Form.List name="ingredients">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space
                    key={field.key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="start"
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "food_id"]}
                      rules={[{ required: true }]}
                    >
                      <Select
                        {...foodSelectProps}
                        placeholder={"Select food"}
                        onSearch={undefined}
                        filterOption={true}
                        optionFilterProp={"label"}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "quantity"]}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder={"Enter quantity"} />
                    </Form.Item>
                    <Form.Item {...field} name={[field.name, "unit"]}>
                      <Select defaultValue="NONE">
                        <Select.Option value="NONE">NONE</Select.Option>
                        <Select.Option value="CUP">CUP</Select.Option>
                        <Select.Option value="TBSP">TBSP</Select.Option>
                      </Select>
                    </Form.Item>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => remove(field.name)}
                    >
                      Remove
                    </Button>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Ingredient
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
    </Create>
  );
};
