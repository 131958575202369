import { ErrorComponent, ThemedLayoutV2 } from "@refinedev/antd";
import {
  CatchAllNavigate,
  NavigateToResource,
} from "@refinedev/react-router-v6";
import { Outlet, Route, Routes } from "react-router-dom";
import "@refinedev/antd/dist/reset.css";
import { BlockList } from "../pages/Block/list";
import { BlockCreate } from "../pages/Block/create";
import { AttachmentCreate } from "../pages/Attachment/create";
import { AttachmentEdit } from "../pages/Attachment/edit";
import { AttachmentShow } from "../pages/Attachment/show";
import { AttachmentList } from "../pages/Attachment/list";
import { BlockShow } from "../pages/Block/show";
import { BlockEdit } from "../pages/Block/edit";
import { ExerciseList } from "../pages/Exercise/list";
import { ExerciseShow } from "../pages/Exercise/show";
import { ExerciseEdit } from "../pages/Exercise/edit";
import { ExerciseCreate } from "../pages/Exercise/create";
import { ExerciseTypeList } from "../pages/ExerciseType/list";
import { ExerciseTypeShow } from "../pages/ExerciseType/show";
import { ExerciseTypeEdit } from "../pages/ExerciseType/edit";
import { ExerciseTypeCreate } from "../pages/ExerciseType/create";
import { FoodList } from "../pages/Food/list";
import { FoodShow } from "../pages/Food/show";
import { FoodEdit } from "../pages/Food/edit";
import { FoodCreate } from "../pages/Food/create";
import { GripList } from "../pages/Grip/list";
import { GripShow } from "../pages/Grip/show";
import { GripEdit } from "../pages/Grip/edit";
import { GripCreate } from "../pages/Grip/create";
import { GripWidthList } from "../pages/GripWidth/list";
import { GripWidthShow } from "../pages/GripWidth/show";
import { GripWidthEdit } from "../pages/GripWidth/edit";
import { GripWidthCreate } from "../pages/GripWidth/create";
import { HandList } from "../pages/Hand/list";
import { HandShow } from "../pages/Hand/show";
import { HandEdit } from "../pages/Hand/edit";
import { HandCreate } from "../pages/Hand/create";
import { MainMuscleList } from "../pages/MainMuscle/list";
import { MainMuscleShow } from "../pages/MainMuscle/show";
import { MainMuscleEdit } from "../pages/MainMuscle/edit";
import { MainMuscleCreate } from "../pages/MainMuscle/create";
import { MealList } from "../pages/Meal/list";
import { MealShow } from "../pages/Meal/show";
import { MealEdit } from "../pages/Meal/edit";
import { MealCreate } from "../pages/Meal/create";
import { MealImageList } from "../pages/MealImage/list";
import { MealImageShow } from "../pages/MealImage/show";
import { MealImageEdit } from "../pages/MealImage/edit";
import { MealImageCreate } from "../pages/MealImage/create";
import { MealInstructionList } from "../pages/MealInstruction/list";
import { MealInstructionShow } from "../pages/MealInstruction/show";
import { MealInstructionEdit } from "../pages/MealInstruction/edit";
import { MealInstructionCreate } from "../pages/MealInstruction/create";
import { PrimaryMuscleList } from "../pages/PrimaryMuscle/list";
import { PrimaryMuscleShow } from "../pages/PrimaryMuscle/show";
import { PrimaryMuscleEdit } from "../pages/PrimaryMuscle/edit";
import { PrimaryMuscleCreate } from "../pages/PrimaryMuscle/create";
import { ProgramList } from "../pages/Program/list";
import { ProgramShow } from "../pages/Program/show";
import { ProgramEdit } from "../pages/Program/edit";
import { ProgramCreate } from "../pages/Program/create";
import { RecipeList } from "../pages/Recipe/list";
import { RecipeShow } from "../pages/Recipe/show";
import { RecipeEdit } from "../pages/Recipe/edit";
import { RecipeCreate } from "../pages/Recipe/create";
import { TipList } from "../pages/Tips/list";
import { TipShow } from "../pages/Tips/show";
import { TipEdit } from "../pages/Tips/edit";
import { TipCreate } from "../pages/Tips/create";
import React from "react";
import { Authenticated } from "@refinedev/core";
import { AuthPage } from "components/pages/auth";
import { Header } from "../components";
import {
  AntdCreateInferencer,
  AntdEditInferencer,
  AntdInferencer,
  AntdListInferencer,
  AntdShowInferencer,
} from "@refinedev/inferencer/antd";
import { EquipmentList } from "../pages/Equipment/list";
import { EquipmentShow } from "../pages/Equipment/show";
import { EquipmentEdit } from "../pages/Equipment/edit";
import { EquipmentCreate } from "../pages/Equipment/create";
import DummyRpeFrontend from "../pages/Dummy/dummyRpeFrontend";
import { LiftingExperienceList } from "../pages/LiftingExperience/list";
import { LiftingExperienceShow } from "../pages/LiftingExperience/show";
import { LiftingExperienceEdit } from "../pages/LiftingExperience/edit";
import { LiftingExperienceCreate } from "../pages/LiftingExperience/create";
import { AppVersionList } from "../pages/AppVersion/list";
import { AppVersionEdit } from "../pages/AppVersion/edit";
import { BlockTemplateList } from "pages/BlockTemplate/list";
import { BlockTemplateShow } from "pages/BlockTemplate/show";
import { BlockTemplateEdit } from "pages/BlockTemplate/edit";
import { BlockTemplateCreate } from "pages/BlockTemplate/create";
import { SplitDayList } from "../pages/SplitDay/list";
import { SplitDayShow } from "../pages/SplitDay/show";
import { SplitDayEdit } from "../pages/SplitDay/edit";
import { SplitDayCreate } from "../pages/SplitDay/create";
import { GroupedDayList } from "../pages/GroupedSplitDays/list";
import { GroupedDayShow } from "../pages/GroupedSplitDays/show";
import { GroupedDayEdit } from "../pages/GroupedSplitDays/edit";
import { GroupedDayCreate } from "../pages/GroupedSplitDays/create";

const header: React.FC = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <img src={"/logo3.png"} alt={"logo"} width={50} height={50} />
    </div>
  );
};

export const getRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <Authenticated
            key={"123"}
            fallback={<CatchAllNavigate to={"/login"} />}
          >
            <ThemedLayoutV2
              Title={header}
              Header={Header}
              initialSiderCollapsed={true}
            >
              <Outlet />
            </ThemedLayoutV2>
          </Authenticated>
        }
      >
        <Route index element={<NavigateToResource resource="attachment" />} />
        <Route path="dummy">
          <Route index element={<DummyRpeFrontend />} />
        </Route>
        <Route path="attachment">
          <Route index element={<AttachmentList />} />
          <Route path="show/:id" element={<AttachmentShow />} />
          <Route path="edit/:id" element={<AttachmentEdit />} />
          <Route path="create" element={<AttachmentCreate />} />
        </Route>
        <Route path="block">
          <Route index element={<BlockList />} />
          <Route path="show/:id" element={<BlockShow />} />
          <Route path="edit/:id" element={<BlockEdit />} />
          <Route path="create" element={<BlockCreate />} />
        </Route>
        <Route path="equipment">
          <Route index element={<EquipmentList />} />
          <Route path="show/:id" element={<EquipmentShow />} />
          <Route path="edit/:id" element={<EquipmentEdit />} />
          <Route path="create" element={<EquipmentCreate />} />
        </Route>
        <Route path="/liftingExperience">
          <Route index element={<LiftingExperienceList />} />
          <Route path="show/:id" element={<LiftingExperienceShow />} />
          <Route path="edit/:id" element={<LiftingExperienceEdit />} />
          <Route path="create" element={<LiftingExperienceCreate />} />
        </Route>
        <Route path="exercise">
          <Route index element={<ExerciseList />} />
          <Route path="show/:id" element={<ExerciseShow />} />
          <Route path="edit/:id" element={<ExerciseEdit />} />
          <Route path="create" element={<ExerciseCreate />} />
        </Route>
        <Route path="exerciseType">
          <Route index element={<ExerciseTypeList />} />
          <Route path="show/:id" element={<ExerciseTypeShow />} />
          <Route path="edit/:id" element={<ExerciseTypeEdit />} />
          <Route path="create" element={<ExerciseTypeCreate />} />
        </Route>
        <Route path="food">
          <Route index element={<FoodList />} />
          <Route path="show/:id" element={<FoodShow />} />
          <Route path="edit/:id" element={<FoodEdit />} />
          <Route path="create" element={<FoodCreate />} />
        </Route>
        <Route path="grip">
          <Route index element={<GripList />} />
          <Route path="show/:id" element={<GripShow />} />
          <Route path="edit/:id" element={<GripEdit />} />
          <Route path="create" element={<GripCreate />} />
        </Route>
        <Route path="gripWidth">
          <Route index element={<GripWidthList />} />
          <Route path="show/:id" element={<GripWidthShow />} />
          <Route path="edit/:id" element={<GripWidthEdit />} />
          <Route path="create" element={<GripWidthCreate />} />
        </Route>
        <Route path="hand">
          <Route index element={<HandList />} />
          <Route path="show/:id" element={<HandShow />} />
          <Route path="edit/:id" element={<HandEdit />} />
          <Route path="create" element={<HandCreate />} />
        </Route>
        <Route path="mainMuscle">
          <Route index element={<MainMuscleList />} />
          <Route path="show/:id" element={<MainMuscleShow />} />
          <Route path="edit/:id" element={<MainMuscleEdit />} />
          <Route path="create" element={<MainMuscleCreate />} />
        </Route>
        <Route path="meal">
          <Route index element={<MealList />} />
          <Route path="show/:id" element={<MealShow />} />
          <Route path="edit/:id" element={<MealEdit />} />
          <Route path="create" element={<MealCreate />} />
        </Route>
        <Route path="mealImage">
          <Route index element={<MealImageList />} />
          <Route path="show/:id" element={<MealImageShow />} />
          <Route path="edit/:id" element={<MealImageEdit />} />
          <Route path="create" element={<MealImageCreate />} />
        </Route>
        <Route path="mealInstruction">
          <Route index element={<MealInstructionList />} />
          <Route path="show/:id" element={<MealInstructionShow />} />
          <Route path="edit/:id" element={<MealInstructionEdit />} />
          <Route path="create" element={<MealInstructionCreate />} />
        </Route>
        <Route path="primaryMuscle">
          <Route index element={<PrimaryMuscleList />} />
          <Route path="show/:id" element={<PrimaryMuscleShow />} />
          <Route path="edit/:id" element={<PrimaryMuscleEdit />} />
          <Route path="create" element={<PrimaryMuscleCreate />} />
        </Route>
        <Route path="program">
          <Route index element={<ProgramList />} />
          <Route path="show/:id" element={<ProgramShow />} />
          <Route path="edit/:id" element={<ProgramEdit />} />
          <Route path="create" element={<ProgramCreate />} />
        </Route>
        <Route path="recipe">
          <Route index element={<RecipeList />} />
          <Route path="show/:id" element={<RecipeShow />} />
          <Route path="edit/:id" element={<RecipeEdit />} />
          <Route path="create" element={<RecipeCreate />} />
        </Route>
        {/*<Route path="recipeIngredient">*/}
        {/*  <Route index element={<RecipeIngredientList />} />*/}
        {/*  <Route path="show/:id" element={<RecipeIngredientShow />} />*/}
        {/*  <Route path="edit/:id" element={<RecipeIngredientEdit />} />*/}
        {/*  <Route path="create" element={<RecipeIngredientCreate />} />*/}
        {/*</Route>*/}
        <Route path="tips">
          <Route index element={<TipList />} />
          <Route path="show/:id" element={<TipShow />} />
          <Route path="edit/:id" element={<TipEdit />} />
          <Route path="create" element={<TipCreate />} />
        </Route>
        <Route path={"split"}></Route>
        <Route path="blockTemplate">
          <Route index element={<BlockTemplateList />} />
          <Route path="show/:id" element={<BlockTemplateShow />} />
          <Route path="edit/:id" element={<BlockTemplateEdit />} />
          <Route path="create" element={<BlockTemplateCreate />} />
        </Route>
        <Route path="splitDay">
          <Route index element={<SplitDayList />} />
          <Route path="show/:id" element={<SplitDayShow />} />
          <Route path="edit/:id" element={<SplitDayEdit />} />
          <Route path="create" element={<SplitDayCreate />} />
        </Route>
        <Route path="groupedSplitDay">
          <Route index element={<GroupedDayList />} />
          <Route path="show/:id" element={<GroupedDayShow />} />
          <Route path="edit/:id" element={<GroupedDayEdit />} />
          <Route path="create" element={<GroupedDayCreate />} />
        </Route>
        <Route path="appVersion">
          <Route index element={<AppVersionList />} />
          <Route path="edit/:id" element={<AppVersionEdit />} />
        </Route>
        <Route path="*" element={<ErrorComponent />} />
      </Route>
      <Route
        element={
          <Authenticated key={"1234"} fallback={<Outlet />}>
            <NavigateToResource />
          </Authenticated>
        }
      >
        <Route path="/login" element={<AuthPage type="login" />} />
      </Route>
    </Routes>
  );
};
