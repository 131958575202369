import { DataProvider } from "@refinedev/core";
import { axiosInstance, generateFilter, generateSort } from "./utils";
import { AxiosInstance } from "axios";
import { stringify } from "query-string";

type MethodTypes = "get" | "delete" | "head" | "options";
type MethodTypesWithBody = "post" | "put" | "patch";

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): Omit<Required<DataProvider>, "createMany" | "updateMany" | "deleteMany"> => {
  const getToken = () => {
    // Retrieve the token from local storage
    return localStorage.getItem("token");
  };

  httpClient.interceptors.request.use((config) => {
    // Add the token to the request headers
    const token = getToken();
    if (token) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        config["headers"] = {
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return config;
  });

  return {
    getList: async ({ resource, pagination, filters, sorters, meta }) => {
      const url = `${apiUrl}/${resource}`;

      const { current = 1, pageSize = 10, mode = "off" } = pagination ?? {};
      const { headers: headersFromMeta, method } = meta ?? {};
      const requestMethod = (method as MethodTypes) ?? "get";

      const queryFilters = generateFilter(filters);

      const query: {
        page?: number;
        limit?: number;
        _sort?: string;
        _order?: string;
      } = {};

      if (mode === "server") {
        query.page = current;
        query.limit = pageSize;
      }

      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        query._sort = _sort.join(",");
        query._order = _order.join(",");
      }

      // Construct the final URL with query parameters
      let trueUrl = url;
      const queryParams = { ...query, ...queryFilters };
      const queryString = stringify(queryParams);
      if (queryString) {
        trueUrl = `${url}?${queryString}`;
      }

      try {
        const { data, headers } = await httpClient[requestMethod](trueUrl, {
          headers: headersFromMeta,
        });

        return {
          data: data.data,
          total: data.total || data.length,
        };
      } catch (error) {
        console.error(error);
        throw new Error("Failed to fetch data");
      }
    },

    getMany: async ({ resource, ids, meta }) => {
      const { headers, method } = meta ?? {};
      const requestMethod = (method as MethodTypes) ?? "get";

      const { data } = await httpClient[requestMethod](
        `${apiUrl}/${resource}?${stringify({ id: ids })}`,
        { headers }
      );

      return {
        data: data.data,
      };
    },

    create: async ({ resource, variables, meta }) => {
      const url = `${apiUrl}/${resource}`;

      const { headers, method } = meta ?? {};
      const requestMethod = (method as MethodTypesWithBody) ?? "post";

      const { data } = await httpClient[requestMethod](url, variables, {
        headers,
      });

      return {
        data: data.data,
      };
    },

    update: async ({ resource, id, variables, meta }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      const { headers, method } = meta ?? {};
      const requestMethod = (method as MethodTypesWithBody) ?? "put";

      const { data } = await httpClient[requestMethod](url, variables, {
        headers,
      });

      return {
        data: data.data,
      };
    },

    getOne: async ({ resource, id, meta }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      const { headers, method } = meta ?? {};
      const requestMethod = (method as MethodTypes) ?? "get";

      const { data } = await httpClient[requestMethod](url, { headers });

      return {
        data: data.data,
      };
    },

    deleteOne: async ({ resource, id, variables, meta }) => {
      const url = `${apiUrl}/${resource}/${id}`;

      const { headers, method } = meta ?? {};
      const requestMethod = (method as MethodTypesWithBody) ?? "delete";

      const { data } = await httpClient[requestMethod](url, {
        data: variables,
        headers,
      });

      return {
        data: data.data,
      };
    },

    getApiUrl: () => {
      return apiUrl;
    },

    custom: async ({
      url,
      method,
      filters,
      sorters,
      payload,
      query,
      headers,
    }) => {
      let requestUrl = `${url}?`;

      if (sorters) {
        const generatedSort = generateSort(sorters);
        if (generatedSort) {
          const { _sort, _order } = generatedSort;
          const sortQuery = {
            _sort: _sort.join(","),
            _order: _order.join(","),
          };
          requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
        }
      }

      if (filters) {
        const filterQuery = generateFilter(filters);
        requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
      }

      if (query) {
        requestUrl = `${requestUrl}&${stringify(query)}`;
      }

      if (headers) {
        httpClient.defaults.headers = {
          ...httpClient.defaults.headers,
          ...headers,
        };
      }

      let axiosResponse;
      switch (method) {
        case "put":
        case "post":
        case "patch":
          axiosResponse = await httpClient[method](url, payload);
          break;
        case "delete":
          axiosResponse = await httpClient.delete(url, {
            data: payload,
          });
          break;
        default:
          axiosResponse = await httpClient.get(requestUrl);
          break;
      }

      const { data } = axiosResponse;
      if (data.token) {
        return Promise.resolve({ data: { token: data.token, ...data.data } });
      } else {
        return Promise.resolve({ data: data.data });
      }
    },
  };
};
