import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  BooleanField,
  DateField,
} from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const BlockTemplateShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Display Name</Title>
      <TextField value={record?.displayName} />
      {record?.muscleFocus && (
        <>
          <Title level={5}>Muscle Focus</Title>
          <TagField value={record?.muscleFocus.name} />
        </>
      )}
      <br />
      <br />
      <Title level={5}>Workout Days Per Week</Title>
      <NumberField value={record?.workoutDaysPerWeek ?? ""} />
      <Title level={5}>Is Active</Title>
      <BooleanField value={record?.isActive} />
      <br />
      <br />
      <Title level={5}>Days</Title>
      {record?.days?.map((day: any) => (
        <TagField key={day.id} value={day.groupedSplitDay.name} />
      ))}
      <br />
      <br />
      <Title level={5}>Created At</Title>
      <DateField value={record?.createdAt} />
      <Title level={5}>Updated At</Title>
      <DateField value={record?.updatedAt} />
    </Show>
  );
};
