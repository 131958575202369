import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { DateField, NumberField, Show, TextField } from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const FoodShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading} canDelete={true} canEdit={true}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Brand Name</Title>
      <TextField value={record?.brand_name} />
      <Title level={5}>Description</Title>
      <TextField value={record?.description} />
      <Title level={5}>Serving Size</Title>
      <TextField value={record?.serving_size} />
      <Title level={5}>Servings Per Container</Title>
      <NumberField value={record?.servings_per_container ?? ""} />
      <Title level={5}>Energy</Title>
      <NumberField value={record?.energy ?? ""} />
      <Title level={5}>Energy Unit</Title>
      <TextField value={record?.energy_unit} />
      <Title level={5}>Protein</Title>
      <NumberField value={record?.protein ?? ""} />
      <Title level={5}>Fat</Title>
      <NumberField value={record?.fat ?? ""} />
      <Title level={5}>Carbohydrates</Title>
      <NumberField value={record?.carbohydrates ?? ""} />
      <Title level={5}>Fiber</Title>
      <NumberField value={record?.fiber ?? ""} />
      <Title level={5}>Starch</Title>
      <NumberField value={record?.starch ?? ""} />
      <Title level={5}>Sugars</Title>
      <NumberField value={record?.sugars ?? ""} />
      <Title level={5}>Added Sugars</Title>
      <NumberField value={record?.added_sugars ?? ""} />
      <Title level={5}>Net Carbs</Title>
      <NumberField value={record?.net_carbs ?? ""} />
      <Title level={5}>Cystine</Title>
      <NumberField value={record?.cystine ?? ""} />
      <Title level={5}>Histidine</Title>
      <NumberField value={record?.histidine ?? ""} />
      <Title level={5}>Isoleucine</Title>
      <NumberField value={record?.isoleucine ?? ""} />
      <Title level={5}>Leucine</Title>
      <NumberField value={record?.leucine ?? ""} />
      <Title level={5}>Lysine</Title>
      <NumberField value={record?.lysine ?? ""} />
      <Title level={5}>Methionine</Title>
      <NumberField value={record?.methionine ?? ""} />
      <Title level={5}>Phenylalanine</Title>
      <NumberField value={record?.phenylalanine ?? ""} />
      <Title level={5}>Threonine</Title>
      <NumberField value={record?.threonine ?? ""} />
      <Title level={5}>Tryptophan</Title>
      <NumberField value={record?.tryptophan ?? ""} />
      <Title level={5}>Tyrosine</Title>
      <NumberField value={record?.tyrosine ?? ""} />
      <Title level={5}>Valine</Title>
      <NumberField value={record?.valine ?? ""} />
      <Title level={5}>Monounsaturated Fat</Title>
      <NumberField value={record?.monounsaturated_fat ?? ""} />
      <Title level={5}>Polyunsaturated Fat</Title>
      <NumberField value={record?.polyunsaturated_fat ?? ""} />
      <Title level={5}>Omega 3</Title>
      <NumberField value={record?.omega_3 ?? ""} />
      <Title level={5}>Omega 3 ALA</Title>
      <NumberField value={record?.omega_3_ALA ?? ""} />
      <Title level={5}>Omega 3 EPA</Title>
      <NumberField value={record?.omega_3_EPA ?? ""} />
      <Title level={5}>Omega 3 DHA</Title>
      <NumberField value={record?.omega_3_DHA ?? ""} />
      <Title level={5}>Omega 6</Title>
      <NumberField value={record?.omega_6 ?? ""} />
      <Title level={5}>Saturated Fat</Title>
      <NumberField value={record?.saturated_fat ?? ""} />
      <Title level={5}>Trans Fat</Title>
      <NumberField value={record?.trans_fat ?? ""} />
      <Title level={5}>B1 Thiamine</Title>
      <NumberField value={record?.b1_thiamine ?? ""} />
      <Title level={5}>B2 Riboflavin</Title>
      <NumberField value={record?.b2_riboflavin ?? ""} />
      <Title level={5}>B3 Niacin</Title>
      <NumberField value={record?.b3_niacin ?? ""} />
      <Title level={5}>B5 Pantothenic Acid</Title>
      <NumberField value={record?.b5_pantothenic_acid ?? ""} />
      <Title level={5}>B6 Pyridoxine</Title>
      <NumberField value={record?.b6_pyridoxine ?? ""} />
      <Title level={5}>B12 Cobalamin</Title>
      <NumberField value={record?.b12_cobalamin ?? ""} />
      <Title level={5}>Folate</Title>
      <NumberField value={record?.folate ?? ""} />
      <Title level={5}>Vitamin A</Title>
      <NumberField value={record?.vitamin_a ?? ""} />
      <Title level={5}>Vitamin C</Title>
      <NumberField value={record?.vitamin_c ?? ""} />
      <Title level={5}>Vitamin D</Title>
      <NumberField value={record?.vitamin_d ?? ""} />
      <Title level={5}>Vitamin E</Title>
      <NumberField value={record?.vitamin_e ?? ""} />
      <Title level={5}>Vitamin K</Title>
      <NumberField value={record?.vitamin_k ?? ""} />
      <Title level={5}>Calcium</Title>
      <NumberField value={record?.calcium ?? ""} />
      <Title level={5}>Copper</Title>
      <NumberField value={record?.copper ?? ""} />
      <Title level={5}>Iron</Title>
      <NumberField value={record?.iron ?? ""} />
      <Title level={5}>Magnesium</Title>
      <NumberField value={record?.magnesium ?? ""} />
      <Title level={5}>Manganese</Title>
      <NumberField value={record?.manganese ?? ""} />
      <Title level={5}>Phosphorus</Title>
      <NumberField value={record?.phosphorus ?? ""} />
      <Title level={5}>Potassium</Title>
      <NumberField value={record?.potassium ?? ""} />
      <Title level={5}>Selenium</Title>
      <NumberField value={record?.selenium ?? ""} />
      <Title level={5}>Sodium</Title>
      <NumberField value={record?.sodium ?? ""} />
      <Title level={5}>Zinc</Title>
      <NumberField value={record?.zinc ?? ""} />
      <Title level={5}>Cholesterol</Title>
      <NumberField value={record?.cholesterol ?? ""} />
      <Title level={5}>Choline</Title>
      <NumberField value={record?.choline ?? ""} />
      <Title level={5}>Alcohol</Title>
      <NumberField value={record?.alcohol ?? ""} />
      <Title level={5}>Caffeine</Title>
      <NumberField value={record?.caffeine ?? ""} />
      <Title level={5}>Water</Title>
      <NumberField value={record?.water ?? ""} />
      <Title level={5}>Created At</Title>
      <DateField value={record?.created_at} />
    </Show>
  );
};
