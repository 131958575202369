import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  DeleteButton,
} from "@refinedev/antd";
import { Table, Space, Tag } from "antd";

export const SplitDayList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="dayName" title="Day Name" />
        <Table.Column
          dataIndex={["muscleEmphasis", "name"]}
          title="Muscle Emphasis"
        />

        <Table.Column
          dataIndex="exercises"
          title="Exercises"
          render={(exercises: any[]) => (
            <div>
              {exercises.map((exercise) => (
                <div key={exercise.id}>
                  <Tag color="blue">{exercise.mainMuscle.name}</Tag>
                  {exercise.secondaryMuscles.map((muscle: any) => (
                    <Tag key={muscle.id} color="purple">
                      {muscle.name}
                    </Tag>
                  ))}
                </div>
              ))}
            </div>
          )}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Updated At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
