import React, { useEffect, useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Card, Form, Input, Select, InputNumber } from "antd";

// Define your interfaces
interface DynamicBlockDay {
  groupedSplitDayId: number | null;
  dayName: string;
}

const getDays = (
  daysData: DynamicBlockDay[],
  groupedSplitDaySelectProps: any
) => {
  return daysData.length > 0 ? (
    <div>
      <h1>
        <b>Week Template</b>
      </h1>
      {daysData.map((day, dayIndex) => (
        <Card
          key={`week-1-day-${dayIndex}`}
          title={`Day ${dayIndex + 1}`}
          bordered={true}
          style={{ marginTop: "16px" }}
        >
          <Form.Item
            name={["days", dayIndex, "groupedSplitDayId"]}
            rules={[
              { required: true, message: "Grouped Split Day is required" },
            ]}
          >
            <Select
              placeholder="Select Grouped Split Day"
              {...groupedSplitDaySelectProps}
              onSearch={undefined}
              filterOption={true}
              optionFilterProp={"label"}
            />
          </Form.Item>
        </Card>
      ))}
      <br />
      <br />
    </div>
  ) : null;
};

export const BlockTemplateEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();
  const { selectProps: groupedSplitDaySelectProps } = useSelect({
    resource: "groupedSplitDay",
    optionLabel: "name",
    optionValue: "id",
  });
  const { selectProps: mainMuscleSelectProps } = useSelect({
    resource: "mainMuscle",
    optionLabel: "name",
    optionValue: "id",
  });
  const [daysPerWeek, setDaysPerWeek] = useState<number>(0);
  const [daysData, setDaysData] = useState<DynamicBlockDay[]>([]);
  const data = queryResult?.data?.data;

  useEffect(() => {
    if (data) {
      setDaysPerWeek(data.workoutDaysPerWeek || 0);
      const initialDays =
        data.days?.map((day: any) => ({
          groupedSplitDayId: day.groupedSplitDay.id,
          dayName: day.groupedSplitDay.name,
        })) || [];
      setDaysData(initialDays);
      formProps.form?.setFieldsValue({
        ...data,
        days: initialDays,
      });
    }
  }, [data, formProps.form]);

  useEffect(() => {
    if (daysPerWeek > 0) {
      const newDays = Array.from({ length: daysPerWeek }, (_, j) => ({
        groupedSplitDayId: daysData[j]?.groupedSplitDayId || null,
        dayName: daysData[j]?.dayName || `Day ${j + 1}`,
      }));

      setDaysData(newDays);
      formProps.form?.setFieldsValue({
        days: newDays,
      });
    } else {
      setDaysData([]);
      formProps.form?.resetFields(["days"]);
    }
  }, [daysPerWeek, formProps.form]);

  const handleDaysPerWeekChange = (value: number | null) => {
    setDaysPerWeek(value ?? 0);
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Display Name"
          name="displayName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Muscle Focus" name="muscleFocusId">
          <Select
            placeholder="Select Muscle Focus"
            {...mainMuscleSelectProps}
            onSearch={undefined}
            filterOption={true}
            optionFilterProp={"label"}
          />
        </Form.Item>
        <Form.Item
          label="Workout Days Per Week"
          name="workoutDaysPerWeek"
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (value >= 0 && value <= 6) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Workout Days Per Week must be between 0 and 6")
                );
              },
            },
          ]}
        >
          <InputNumber
            value={daysPerWeek}
            onChange={(value) => handleDaysPerWeekChange(value as number)}
          />
        </Form.Item>
        {daysPerWeek > 0 &&
          daysPerWeek <= 6 &&
          getDays(daysData, groupedSplitDaySelectProps)}
      </Form>
    </Edit>
  );
};
