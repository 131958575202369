import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, DatePicker, Checkbox, Select } from "antd";
import dayjs from "dayjs";

export const ProgramCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: exercisesSelectProps } = useSelect({
    resource: "exercise",
  });

  const { selectProps: blocksSelectProps } = useSelect({
    resource: "block",
    optionLabel: "name",
  });
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Goal Rpe"
          name={["goal_rpe"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name={["predefined"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox defaultChecked={true}>Predefined</Checkbox>
        </Form.Item>
        <Form.Item
          label="Training Days Per Week"
          name={["training_days_per_week"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Blocks"
          name={"blocks"}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value: any[]) => {
            return {
              value: value?.map((item) => item?.name),
            };
          }}
          getValueFromEvent={(selected: string[]) => {
            return selected?.map((item) => ({ name: item }));
          }}
        >
          <Select mode="multiple" {...blocksSelectProps} />
        </Form.Item>
        <Form.Item
          label="Exercises"
          name={"exercises"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select mode="multiple" {...exercisesSelectProps} />
        </Form.Item>
        <Form.Item
          label="Created At"
          name={["created_at"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker defaultValue={dayjs()} />
        </Form.Item>
      </Form>
    </Create>
  );
};
