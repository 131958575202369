import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, InputNumber, Select } from "antd";

export const FoodCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Brand Name"
          name={["brand_name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Serving Size"
          name={["serving_size"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Servings Per Container"
          name={["servings_per_container"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Energy"
          name={["energy"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Energy Unit" name={["energy_unit"]}>
          <Select defaultValue={"KCAL"}>
            <Select.Option value="KCAL">KCAL</Select.Option>
            <Select.Option label={"KJ"}>KJ</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Protein"
          name={["protein"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Fat"
          name={["fat"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Carbohydrates"
          name={["carbohydrates"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Fiber"
          name={["fiber"]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Starch" name={["starch"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Sugars" name={["sugars"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Added Sugars" name={["added_sugars"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Net Carbs" name={["net_carbs"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Cystine" name={["cystine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Histidine" name={["histidine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Isoleucine" name={["isoleucine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Leucine" name={["leucine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Lysine" name={["lysine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Methionine" name={["methionine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Phenylalanine" name={["phenylalanine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Threonine" name={["threonine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Tryptophan" name={["tryptophan"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Tyrosine" name={["tyrosine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Valine" name={["valine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Monounsaturated Fat" name={["monounsaturated_fat"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Polyunsaturated Fat" name={["polyunsaturated_fat"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Omega 3" name={["omega_3"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Omega 3 ALA" name={["omega_3_ALA"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Omega 3 EPA" name={["omega_3_EPA"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Omega 3 DHA" name={["omega_3_DHA"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Omega 6" name={["omega_6"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Saturated Fat" name={["saturated_fat"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Trans Fat" name={["trans_fat"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="B1 Thiamine" name={["b1_thiamine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="B2 Riboflavin" name={["b2_riboflavin"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="B3 Niacin" name={["b3_niacin"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="B5 Pantothenic Acid" name={["b5_pantothenic_acid"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="B6 Pyridoxine" name={["b6_pyridoxine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="B12 Cobalamin" name={["b12_cobalamin"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Folate" name={["folate"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Vitamin A" name={["vitamin_a"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Vitamin C" name={["vitamin_c"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Vitamin D" name={["vitamin_d"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Vitamin E" name={["vitamin_e"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Vitamin K" name={["vitamin_k"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Calcium" name={["calcium"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Copper" name={["copper"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Iron" name={["iron"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Magnesium" name={["magnesium"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Manganese" name={["manganese"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Phosphorus" name={["phosphorus"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Potassium" name={["potassium"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Selenium" name={["selenium"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Sodium" name={["sodium"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Zinc" name={["zinc"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Cholesterol" name={["cholesterol"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Choline" name={["choline"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Alcohol" name={["alcohol"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Caffeine" name={["caffeine"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Water" name={["water"]}>
          <InputNumber />
        </Form.Item>
      </Form>
    </Create>
  );
};
