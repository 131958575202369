import React from "react";
import { IResourceComponentsProps, useShow, useMany } from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  DateField,
  BooleanField,
} from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const ProgramShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: exercisesData, isLoading: exercisesIsLoading } = useMany({
    resource: "exercise",
    ids: record?.exercises || [],
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading}  canDelete={true}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Description</Title>
      <TextField value={record?.description} />
      <Title level={5}>Goal Rpe</Title>
      <DateField value={record?.goal_rpe} />
      <Title level={5}>Predefined</Title>
      <BooleanField value={record?.predefined} />
      <Title level={5}>Training Days Per Week</Title>
      <NumberField value={record?.training_days_per_week ?? ""} />
      <Title level={5}>Created At</Title>
      <DateField value={record?.created_at} />
      <Title level={5}>Exercises</Title>
      {exercisesIsLoading ? <>Loading...</> : <></>}
    </Show>
  );
};
