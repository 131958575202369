import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  ImageField,
  TagField,
} from "@refinedev/antd";
import { Table, Space } from "antd";

export const MealList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { data: foodsData, isLoading: foodsIsLoading } = useMany({
    resource: "food",
    ids: [].concat(
      ...(tableProps?.dataSource?.map((item) => item?.foods) ?? [])
    ),
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="default_type" title="Default Type" />
        <Table.Column
          dataIndex={["created_at"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />

        <Table.Column
          dataIndex="images"
          title="Images"
          render={(value: any[]) => (
            <>
              {value?.map((item, index) => (
                <ImageField
                  style={{ maxWidth: "100px" }}
                  value={item?.url}
                  key={index}
                />
              ))}
            </>
          )}
        />
        <Table.Column
          dataIndex="foods"
          title="Foods"
          render={(value: any[]) =>
            foodsIsLoading ? (
              <>Loading...</>
            ) : (
              <>
                {value?.map((item, index) => (
                  <TagField key={index} value={item} />
                ))}
              </>
            )
          }
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
