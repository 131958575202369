import React from "react";
import { BaseRecord, IResourceComponentsProps } from "@refinedev/core";
import {
  Edit,
  getValueFromEvent,
  useForm,
  useSelect,
} from "@refinedev/antd";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Upload,
} from "antd";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Day, DayExercise, ExerciseSet, Week } from "../../custom";

export const BlockEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const blockData: BaseRecord | undefined = queryResult?.data?.data;

  const { selectProps: mainMuscleSelectProps } = useSelect({
    resource: "mainMuscle",
    optionLabel: "name",
  });

  const { selectProps: exerciseTypeSelectProps } = useSelect({
    resource: "exerciseType",
    optionLabel: "name",
  });
  const { selectProps: exerciseSelectProps } = useSelect({
    resource: "exercise",
    optionLabel: "exercise_name",
  });
  const { selectProps: primaryMuscleSelectProps } = useSelect({
    resource: "primaryMuscle",
    optionLabel: "name",
  });
  const { selectProps: liftingExperienceSelectProps } = useSelect({
    resource: "liftingExperience",
    optionLabel: "name",
  });

  const generateWeeks = (weeks: Week[]) => {
    return weeks?.map((week, weekIndex) => (
      <Card
        key={`week-${week.id}`}
        bordered={true}
        title={`Week #${week.week_number}`}
      >
        <Form.Item label="Id" name={["weeks", weekIndex, "id"]} hidden={true}>
          <InputNumber readOnly disabled />
        </Form.Item>
        <Form.Item
          label="blockId"
          name={["weeks", weekIndex, "blockId"]}
          hidden={true}
        >
          <InputNumber readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Week Number"
          name={["weeks", weekIndex, "week_number"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label="Week Name" name={["weeks", weekIndex, "week_name"]}>
          <Input disabled={true} />
        </Form.Item>
        {generateDays(
          week.days.sort((a: Day, b: Day) => a.day_number - b.day_number),
          week.id,
          weekIndex
        )}
      </Card>
    ));
  };

  const generateDays = (days: Day[], weekId: number, weekIndex: number) => {
    return days?.map((day, dayIndex) => (
      <Card
        key={`week-${weekId}-day-${day.id}`}
        title={`Day ${day.day_number}`}
        bordered={true}
        style={{ marginTop: "1.5%" }}
      >
        <Form.Item
          label="Id"
          name={["weeks", weekIndex, "days", dayIndex, "id"]}
          hidden={true}
        >
          <InputNumber readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Day Number"
          name={["weeks", weekIndex, "days", dayIndex, "day_number"]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Day Name"
          name={["weeks", weekIndex, "days", dayIndex, "day_name"]}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Day Image"
          name={["weeks", weekIndex, "days", dayIndex, "day_image"]}
          getValueFromEvent={getValueFromEvent}
        >
          <Upload.Dragger
            action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=dayImages`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }}
            maxCount={1}
            fileList={
              day.day_image
                ? [
                    {
                      uid: "-1",
                      name: day.day_name,
                      status: "done",
                      url: day.day_image,
                    },
                  ]
                : [].filter(Boolean)
            }
            listType="picture-card"
          >
            <p className="ant-upload-text">Drag & drop a file in this area</p>
          </Upload.Dragger>
          {generateDayExercises(day?.exercises, dayIndex, weekIndex)}
        </Form.Item>
      </Card>
    ));
  };

  const generateDayExercises = (
    initialExercises: DayExercise[],
    dayIndex: number,
    weekIndex: number // Add this parameter
  ) => {
    return (
      <Card title={"Exercises"} bordered={true} style={{ marginTop: "3%" }}>
        <Form.List
          name={["weeks", weekIndex, "days", dayIndex, "exercises"]} // Updated to reflect nested structure
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Card
                  key={`${field.key}-${field.name}`}
                  style={{ marginBottom: "8px" }}
                  title={`Exercise #${index + 1}`}
                >
                  <Space align="baseline">
                    {/* Wrap each control in its own Form.Item */}
                    <Form.Item
                      label={"Id"}
                      name={[field.name, "id"]}
                      fieldKey={[field.fieldKey!, "id"]}
                      hidden={true}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      label={"Day Id"}
                      name={[field.name, "dayId"]}
                      fieldKey={[field.fieldKey!, "dayId"]}
                      hidden={true}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      label={"Exercise"}
                      name={[field.name, "exerciseId"]}
                      fieldKey={[field.fieldKey!, "exerciseId"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select an exercise",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select an Exercise"
                        {...exerciseSelectProps}
                      />
                    </Form.Item>
                    <Form.Item
                      label={"Duration"}
                      name={[field.name, "duration"]}
                      fieldKey={[field.fieldKey!, "duration"]}
                    >
                      <InputNumber placeholder="Duration" />
                    </Form.Item>
                    <Form.Item
                      label={"Muscle Focus"}
                      name={[field.name, "muscleFocusId"]}
                      fieldKey={[field.fieldKey!, "muscleFocusId"]}
                    >
                      <Select
                        placeholder="Select Muscle Focus"
                        {...primaryMuscleSelectProps}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                  {generateSets(
                    (initialExercises[index]?.exerciseSets || []),
                    field,
                    weekIndex,
                    dayIndex,
                    index
                  )}
                </Card>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() =>
                    add({
                      exerciseId: undefined,
                      duration: undefined,
                      muscleFocusId: undefined,
                    })
                  }
                  block
                  icon={<PlusOutlined />}
                >
                  Add Exercise
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Card>
    );
  };

  const generateSets = (
    exerciseField: ExerciseSet[],
    field: any,
    weekIndex: number,
    dayIndex: number,
    exerciseIndex: number
  ) => {
    return (
      <Form.List
        name={[
          field.name, "exerciseSets"
            ]}
        // initialValue={exerciseField}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Card
                key={field.name} // Use `field.name` as key, which is unique per set
                title={`Set #${index + 1}`}
              >
                <Row gutter={16}>
                  {/* Each Form.Item now has a uniquely constructed `fieldKey` based on `field.fieldKey` and a unique suffix */}
                  <Form.Item
                    {...field}
                    name={[field.name, "id"]}
                    key={`${field.fieldKey}-${exerciseField[index]?.id}-id`}
                    hidden={true}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "dayExerciseId"]}
                    key={`${field.fieldKey}-${exerciseField[index]?.id}-dayExerciseId`}
                    hidden={true}
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    label="Rep Target"
                    key={`${field.fieldKey}-${exerciseField[index]?.id}-rep-target`}
                    name={[field.name, "rep_target"]}
                    fieldKey={[
                      `${field.fieldKey}-${exerciseField[index]?.id}-rep_target`,
                      "rep_target",
                    ]}
                    rules={[
                      { required: true, message: "Please input Rep Target!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    label="Goal RPE"
                    key={`${field.fieldKey}-${exerciseField[index]?.id}-rpe`}
                    name={[field.name, "goal_rpe"]}
                    fieldKey={[
                      `${field.fieldKey}-${exerciseField[index]?.id}-goal_rpe`,
                      "goal_rpe",
                    ]}
                    rules={[
                      { required: true, message: "Please input Goal RPE!" },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    label="Rest Time"
                    key={`${field.fieldKey}-${exerciseField[index]?.id}-rest-time`}
                    name={[field.name, "rest_time"]}
                    fieldKey={[
                      `${field.fieldKey}-${exerciseField[index]?.id}-rest_time`,
                      "rest_time",
                    ]}
                    rules={[
                      { required: true, message: "Please input Rest Time!" },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    key={`${field.fieldKey}-${exerciseField[index]?.id}-amrap`}
                    name={[field.name, "amrap"]}
                    fieldKey={[
                      `${field.fieldKey}-${exerciseField[index]?.id}-amrap`,
                      "amrap",
                    ]}
                    valuePropName="checked"
                  >
                    <Checkbox>AMRAP</Checkbox>
                  </Form.Item>

                  <Button
                    danger
                    onClick={() => remove(field.name)}
                    style={{ marginRight: "5px" }}
                    icon={<DeleteOutlined />}
                  >
                    Remove
                  </Button>
                </Row>
              </Card>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() =>
                  add({
                    rep_target: "",
                    goal_rpe: null,
                    rest_time: null,
                    amrap: false,
                  })
                }
                icon={<PlusOutlined />}
              >
                Add Set
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Id"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Experience Level"
          name={["liftingExperienceId"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Lifting Experience"
            {...liftingExperienceSelectProps}
            onSearch={undefined}
            filterOption={true}
            optionFilterProp={"label"}
          />
        </Form.Item>
        <Form.Item
          label="Block Length In Weeks"
          name={["block_length_in_weeks"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="Workout Days Per Week"
          name={["workout_days_per_week"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={true} />
        </Form.Item>
        {generateWeeks(
          blockData?.weeks?.sort(
            (a: Week, b: Week) => a.week_number - b.week_number
          )
        )}
      </Form>
    </Edit>
  );
};
