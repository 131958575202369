import React, { useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Upload } from "antd";
import { onImageChange } from "../../utils/misc.utils";
import TextArea from "antd/lib/input/TextArea";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

export const ExerciseEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();
  const [loading, setLoading] = useState(false);
  const exerciseData = queryResult?.data?.data;
  const [value, setValue] = React.useState(exerciseData?.exerciseGuide || "");

  React.useEffect(() => {
    // Prefill the form with the existing knowledge base data
    formProps.form!.setFieldsValue({
      content: exerciseData?.exerciseGuide,
    });
    // Update the state for the markdown editor and image URL
    setValue(exerciseData?.exerciseGuide);
  }, [exerciseData, formProps.form]);

  const { selectProps: attachmentSelectProps } = useSelect({
    resource: "attachment",
    defaultValue: exerciseData?.attachmentId,
    optionLabel: "name",
  });

  const { selectProps: gripSelectProps } = useSelect({
    resource: "grip",
    defaultValue: exerciseData?.gripId,
    optionLabel: "name",
  });

  const { selectProps: handsSelectProps } = useSelect({
    resource: "hand",
    defaultValue: exerciseData?.handsId,
    optionLabel: "name",
  });

  const { selectProps: mainMuscleSelectProps } = useSelect({
    resource: "mainMuscle",
    defaultValue: exerciseData?.mainMuscleId,
    optionLabel: "name",
  });

  const { selectProps: primaryMuscleSelectProps } = useSelect({
    resource: "primaryMuscle",
    defaultValue: exerciseData?.primaryMuscleId,
    optionLabel: "name",
  });

  const { selectProps: supportingMusclesSelectProps } = useSelect({
    resource: "primaryMuscle",
    defaultValue: exerciseData?.supportingMuscleIds,
    optionLabel: "name",
    meta: {
      mode: "multiple",
    },
  });

  const { selectProps: secondaryMusclesSelectProps } = useSelect({
    resource: "primaryMuscle",
    defaultValue: exerciseData?.secondaryMuscleIds,
    optionLabel: "name",
  });

  const { selectProps: equipmentsSelectProps } = useSelect({
    resource: "equipment",
    optionLabel: "name",
    defaultValue: exerciseData?.equipments,
  });

  const { selectProps: exerciseTypeSelectProps } = useSelect({
    resource: "exerciseType",
    optionLabel: "name",
    defaultValue: exerciseData?.exercise_typeId,
  });

  const { selectProps: gripWidthSelectProps } = useSelect({
    resource: "gripWidth",
    optionLabel: "name",
    defaultValue: exerciseData?.grip_widthId,
  });

  const { selectProps: tipsSelectProps } = useSelect({
    resource: "tips",
    optionLabel: "tip",
    defaultValue: exerciseData?.tips,
  });

  const defaultSupportingMuscles = exerciseData?.supportingMuscles?.map(
    (muscle: { id: any }) => muscle.id
  );
  const defaultSecondaryMuscles = exerciseData?.secondaryMuscles?.map(
    (muscle: { id: any }) => muscle.id
  );

  const handleEditorChange = (newValue: string | undefined) => {
    if (newValue !== undefined) {
      setValue(newValue);
      // Update the form value for the content field
      formProps.form!.setFieldsValue({
        exerciseGuide: newValue,
      });
    }
  };

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      isLoading={loading}
      canDelete={true}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Id"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Exercise Name"
          name={["exercise_name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Image"}
          name={["image"]}
          // valuePropName={"fileList"}
          getValueFromEvent={getValueFromEvent}
        >
          <Upload.Dragger
            action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=exerciseImages`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }}
            maxCount={1}
            defaultFileList={[
              exerciseData &&
                exerciseData.image && {
                  uid: "-1",
                  name: exerciseData.exercise_name,
                  status: "done",
                  url: exerciseData.image,
                },
            ].filter(Boolean)}
            listType="picture-card"
          >
            <p className="ant-upload-text">Drag & drop a file in this area</p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item
          label="Description"
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Duration" name={["duration"]}>
          <Input />
        </Form.Item>
        <Form.Item label="Youtube URL" name={["youtube_url"]}>
          <Input />
        </Form.Item>
        <Form.Item label="Male Animation" name={["male_animation_url"]}>
          <Upload.Dragger
            defaultFileList={
              exerciseData?.male_animation_url
                ? [
                    {
                      uid: "-1",
                      name: exerciseData.exercise_name + " Male Animation", // Fallback name if exercise_name is not available
                      status: "done", // Make sure to set the status
                      url: exerciseData.male_animation_url,
                    },
                  ]
                : []
            }
            listType="picture-card"
            onChange={async (info) => {
              setLoading(true);
              await onImageChange({
                file: info.file,
                formProps,
                fieldName: "male_animation_url",
              });
              setLoading(false);
            }}
            beforeUpload={() => false}
            onRemove={() => {
              formProps.form!.setFieldsValue({ male_animation_url: "" });
              // TODO: remove image from s3
            }}
          >
            <p className="ant-upload-text">Drag & drop a file in this area</p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item label="Female Animation" name={["female_animation_url"]}>
          <Upload.Dragger
            defaultFileList={
              exerciseData?.female_animation_url
                ? [
                    {
                      uid: "-2",
                      name: exerciseData.exercise_name + " Female Animation", // Fallback name if exercise_name is not available
                      status: "done", // Make sure to set the status
                      url: exerciseData.female_animation_url,
                    },
                  ]
                : []
            }
            listType="picture-card"
            onChange={async (info) => {
              setLoading(true);
              await onImageChange({
                file: info.file,
                formProps,
                fieldName: "female_animation_url",
              });
              setLoading(false);
            }}
            beforeUpload={() => false}
            onRemove={() => {
              formProps.form!.setFieldsValue({ female_animation_url: "" });
              // TODO: remove image from s3
            }}
          >
            <p className="ant-upload-text">Drag & drop a file in this area</p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item label="Male Thumbnail" name={["male_thumbnail_url"]}>
          <Upload.Dragger
            defaultFileList={
              exerciseData?.male_thumbnail_url
                ? [
                    {
                      uid: "-1",
                      name: exerciseData.exercise_name + " Male Thumbnail", // Fallback name if exercise_name is not available
                      status: "done", // Make sure to set the status
                      url: exerciseData.male_thumbnail_url,
                    },
                  ]
                : []
            }
            listType="picture-card"
            onChange={async (info) => {
              setLoading(true);
              await onImageChange({
                file: info.file,
                formProps,
                fieldName: "male_thumbnail_url",
              });
              setLoading(false);
            }}
            beforeUpload={() => false}
            onRemove={() => {
              formProps.form!.setFieldsValue({ male_thumbnail_url: "" });
              // TODO: remove image from s3
            }}
          >
            <p className="ant-upload-text">Drag & drop a file in this area</p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item label="Female Thumbnail" name={["female_thumbnail_url"]}>
          <Upload.Dragger
            defaultFileList={
              exerciseData?.female_thumbnail_url
                ? [
                    {
                      uid: "-1",
                      name: exerciseData.exercise_name + " Female Thumbnail", // Fallback name if exercise_name is not available
                      status: "done", // Make sure to set the status
                      url: exerciseData.female_thumbnail_url,
                    },
                  ]
                : []
            }
            listType="picture-card"
            onChange={async (info) => {
              setLoading(true);
              await onImageChange({
                file: info.file,
                formProps,
                fieldName: "female_thumbnail_url",
              });
              setLoading(false);
            }}
            beforeUpload={() => false}
            onRemove={() => {
              formProps.form!.setFieldsValue({ female_thumbnail_url: "" });
              // TODO: remove image from s3
            }}
          >
            <p className="ant-upload-text">Drag & drop a file in this area</p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item label="Attachment" name={"attachmentId"}>
          <Select {...attachmentSelectProps} allowClear />
        </Form.Item>
        <Form.Item label="Movement" name={["movement"]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Instructions"
          name={["instructions"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Main Muscle"
          name={["mainMuscleId"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...mainMuscleSelectProps} />
        </Form.Item>
        <Form.Item label={"Coach Comments"} name={["coachComments"]}>
          <TextArea
            placeholder={"Coach Comments"}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item label={"Exercise Guide"} name={["exerciseGuide"]}>
          <MDEditor
            data-color-mode="light"
            value={value}
            onChange={handleEditorChange}
            height={500}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
          />{" "}
        </Form.Item>
        <Form.Item label={"Cautions"} name={["cautions"]}>
          <TextArea
            placeholder={"Cautions"}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item label="Grip" name={["gripId"]}>
          <Select {...gripSelectProps} allowClear />
        </Form.Item>
        <Form.Item label="Hands" name={["handsId"]}>
          <Select {...handsSelectProps} allowClear />
        </Form.Item>
        <Form.Item label="Primary Muscle" name={["primaryMuscleId"]}>
          <Select {...primaryMuscleSelectProps} allowClear />
        </Form.Item>
        <Form.Item label="Supporting Muscle" name={["supportingMuscleIds"]}>
          <Select
            {...supportingMusclesSelectProps}
            mode="multiple"
            allowClear
          />
        </Form.Item>

        <Form.Item label="Secondary Muscle" name={["secondaryMuscleIds"]}>
          <Select {...secondaryMusclesSelectProps} mode="multiple" allowClear />
        </Form.Item>
        <Form.Item label="Exercise Type" name={["exercise_typeId"]}>
          <Select {...exerciseTypeSelectProps} allowClear />
        </Form.Item>
        <Form.Item label="Grip Width" name={["grip_widthId"]}>
          <Select {...gripWidthSelectProps} allowClear />
        </Form.Item>
        <Form.Item
          label="Equipments"
          name={"equipments"}
          getValueProps={(value: any[]) => {
            return {
              value: value?.map((item) => item?.id),
            };
          }}
          getValueFromEvent={(selected: string[]) => {
            return selected?.map((item) => ({ id: item }));
          }}
        >
          <Select mode="multiple" {...equipmentsSelectProps} allowClear />
        </Form.Item>
        <Form.Item
          label="Tips"
          name={"tips"}
          getValueProps={(value: any[]) => {
            return {
              value: value?.map((item) => item?.id),
            };
          }}
          getValueFromEvent={(selected: string[]) => {
            return selected?.map((item) => ({ id: item }));
          }}
        >
          <Select mode="multiple" {...tipsSelectProps} allowClear />
        </Form.Item>
      </Form>
    </Edit>
  );
};
