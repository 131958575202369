import React from "react";
import { IResourceComponentsProps, useShow, useOne } from "@refinedev/core";
import { Show, NumberField, ImageField, DateField } from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const MealImageShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: mealData, isLoading: mealIsLoading } = useOne({
    resource: "meal",
    id: record?.meal_id || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading}  canDelete={true}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Url</Title>
      <ImageField style={{ maxWidth: 200 }} value={record?.url} />
      <Title level={5}>Meal</Title>
      {mealIsLoading ? <>Loading...</> : <>{mealData?.data}</>}
      <Title level={5}>Created At</Title>
      <DateField value={record?.created_at} />
    </Show>
  );
};
