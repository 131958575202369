import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Upload } from "antd";

export const PrimaryMuscleCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: mainMuscleSelectProps } = useSelect({
    resource: "mainMuscle",
    optionLabel: "name",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Male Front Muscle Image">
          <Form.Item
            name={"male_front_image_url"}
            valuePropName={"fileList"}
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              listType="picture-card"
              action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=mainMuscleImg`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              multiple={true}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Female Front Muscle Image">
          <Form.Item
            name={"female_front_image_url"}
            valuePropName={"fileList"}
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              listType="picture-card"
              action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=mainMuscleImg`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              multiple={true}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Male Back Muscle Image">
          <Form.Item
              name={"male_back_image_url"}
              valuePropName={"fileList"}
              getValueFromEvent={getValueFromEvent}
              noStyle
          >
            <Upload.Dragger
                listType="picture-card"
                action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=mainMuscleImg`}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                multiple={true}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Female Back Muscle Image">
          <Form.Item
              name={"female_back_image_url"}
              valuePropName={"fileList"}
              getValueFromEvent={getValueFromEvent}
              noStyle
          >
            <Upload.Dragger
                listType="picture-card"
                action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=mainMuscleImg`}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                multiple={true}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Main Muscle"
          name={"mainMuscleId"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...mainMuscleSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
