import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select } from "antd";

export const TipCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: tipSelectProps } = useSelect({
    resource: "tips",
  });

  const { selectProps: exerciseSelectProps } = useSelect({
    resource: "exercise",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Tip"
          name={"tip"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...tipSelectProps} />
        </Form.Item>
        <Form.Item
          label="Exercise"
          name={"exerciseId"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...exerciseSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
