import React from "react";
import { BaseRecord, IResourceComponentsProps } from "@refinedev/core";
import {
  DeleteButton,
  EditButton,
  ImageField,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import { Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const RecipeList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
    pagination: {
      mode: "off",
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex="name"
          title="Name"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
              <Input
                placeholder="Search Recipe"
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </div>
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          )}
          onFilter={(value: any, record: any) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase())
          }
        />
        <Table.Column dataIndex="serves" title="Serves" />
        <Table.Column
          dataIndex="number_of_servings"
          title="Number Of Servings"
        />
        <Table.Column dataIndex="type" title="Type" />
        <Table.Column dataIndex="calories" title="Calories" />
        <Table.Column dataIndex="protein" title="Protein" />
        <Table.Column dataIndex="fat" title="Fat" />
        <Table.Column dataIndex="carbs" title="Carbs" />
        <Table.Column dataIndex="recipe_type" title="Type" />
        <Table.Column dataIndex="preparation_time" title="Preparation Time" />
        <Table.Column
          dataIndex="instructions"
          title="Instructions"
          render={(value: any) => {
            if (value.length > 100) {
              return value.substring(0, 100) + "...";
            } else {
              return value;
            }
          }}
        />
        <Table.Column
          dataIndex="image"
          title="Image"
          render={(value: any) => (
            <ImageField style={{ maxWidth: "100px" }} value={value} />
          )}
        />

        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
