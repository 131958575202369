import React from "react";
import { IResourceComponentsProps, useMany, useShow } from "@refinedev/core";
import { NumberField, Show, TagField, TextField } from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const MainMuscleShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading} canDelete={true}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Description</Title>
      <TextField value={record?.description} />
      <Title level={5}>Muscles</Title>
      {record?.muscles?.map((item: any) => (
        <TagField value={item?.name} key={item?.name} />
      ))}
    </Show>
  );
};
