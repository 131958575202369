import React from "react";
import { IResourceComponentsProps, useOne, useShow } from "@refinedev/core";
import { NumberField, Show, TextField } from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const PrimaryMuscleShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: mainMuscleData, isLoading: mainMuscleIsLoading } = useOne({
    resource: "mainMuscle",
    id: record?.mainMuscleId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading} canDelete={true}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Description</Title>
      <TextField value={record?.description} />
      <Title level={5}>Male Front Muscle Image</Title>
      {record?.male_front_image_url?.map((value: string) => (
        <img src={value} alt="Muscle_Image" width="220" height="240" />
      ))}
      <Title level={5}>Female Front Muscle Image</Title>
      {record?.female_front_image_url?.map((value: string) => (
        <img src={value} alt="Muscle_Image" width="220" height="240" />
      ))}
      <Title level={5}>Male Back Muscle Image</Title>
      {record?.male_back_image_url?.map((value: string) => (
        <img src={value} alt="Muscle_Image" width="220" height="240" />
      ))}
      <Title level={5}>Female Back Muscle Image</Title>
      {record?.female_back_image_url?.map((value: string) => (
        <img src={value} alt="Muscle_Image" width="220" height="240" />
      ))}
      <Title level={5}>Main Muscle</Title>
      {mainMuscleIsLoading ? (
        <>Loading...</>
      ) : (
        <>{mainMuscleData?.data?.name}</>
      )}
    </Show>
  );
};
