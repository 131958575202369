import React from "react";
import { IResourceComponentsProps, useShow, useOne } from "@refinedev/core";
import { Show, NumberField } from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const TipShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: tipData, isLoading: tipIsLoading } = useOne({
    resource: "tips",
    id: record?.tip || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  const { data: exerciseData, isLoading: exerciseIsLoading } = useOne({
    resource: "exercise",
    id: record?.exerciseId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading} canDelete={true}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Tip</Title>
      {tipIsLoading ? <>Loading...</> : <>{tipData?.data?.id}</>}
      <Title level={5}>Exercise</Title>
      {exerciseIsLoading ? <>Loading...</> : <>{exerciseData?.data}</>}
    </Show>
  );
};
