export const uploadToPresignedUrl = async (url: string, file: File) => {
  return await fetch(url, {
    method: "PUT",
    body: file,
  }).then((res) => {
    if (res.ok) {
      return {
        url: res.url,
      };
    } else {
      return {
        status: "error",
        response: { message: "Upload failed" },
      };
    }
  });
};
