import React from "react";
import { IResourceComponentsProps, useOne, useShow } from "@refinedev/core";
import {
  DateField,
  ImageField,
  NumberField,
  Show,
  TextField,
} from "@refinedev/antd";
import { Typography } from "antd";
import rehypeSanitize from "rehype-sanitize";
import ReactMarkdown from "react-markdown";

const { Title } = Typography;

export const ExerciseShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: attachmentData, isLoading: attachmentIsLoading } = useOne({
    resource: "attachment",
    id: record?.attachmentId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  const { data: gripData, isLoading: gripIsLoading } = useOne({
    resource: "grip",
    id: record?.gripId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  const { data: handsData, isLoading: handsIsLoading } = useOne({
    resource: "hand",
    id: record?.handsId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  const { data: mainMuscleData, isLoading: mainMuscleIsLoading } = useOne({
    resource: "mainMuscle",
    id: record?.mainMuscleId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  const { data: primaryMuscleData, isLoading: primaryMuscleIsLoading } = useOne(
    {
      resource: "primaryMuscle",
      id: record?.primaryMuscleId || "",
      queryOptions: {
        enabled: !!record,
      },
    }
  );

  return (
    <Show isLoading={isLoading} canDelete={true}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Exercise Name</Title>
      <TextField value={record?.exercise_name} />
      <Title level={5}>Image</Title>
      <img src={record?.image} alt="Exercise_Image" width="320" height="240" />
      <Title level={5}>Description</Title>
      <TextField value={record?.description} />
      <Title level={5}>Duration</Title>
      <NumberField value={record?.duration ?? ""} />
      <Title level={5}>Male Animation</Title>
      {
        <a href={record?.male_animation_url} target="_blank" rel="noreferrer">
          {record?.male_animation_url}
        </a>
      }
      <br />
      <br />
      <Title level={5}>Female Animation</Title>
      {
        <a href={record?.female_animation_url} target="_blank" rel="noreferrer">
          {record?.female_animation_url}
        </a>
      }
      <br />
      <br />
      <Title level={5}>Male Thumbnail</Title>
      <ImageField value={record?.male_thumbnail_url} />
      <br />
      <br />
      <Title level={5}>Female Thumbnail</Title>
      <ImageField value={record?.female_thumbnail_url} />
      <br />
      <br />
      <Title level={5}> Coach Comments</Title>
      <TextField value={record?.coachComments} />
      <Title level={5}>Exercise Guide</Title>
      <div
        style={{ border: "1px solid #f0f0f0", padding: "20px", margin: "50px" }}
      >
        <ReactMarkdown rehypePlugins={[rehypeSanitize]}>
          {record?.exerciseGuide ?? ""}
        </ReactMarkdown>
      </div>
      <Title level={5}>Cautions</Title>
      <TextField value={record?.cautions} />
      <Title level={5}>Attachment</Title>
      {attachmentIsLoading ? (
        <>Loading...</>
      ) : (
        <>{attachmentData?.data?.name}</>
      )}
      <Title level={5}>Movement</Title>
      <TextField value={record?.movement} />
      <Title level={5}>Instructions</Title>
      <TextField value={record?.instructions} />
      <Title level={5}>Grip</Title>
      {gripIsLoading ? <>Loading...</> : <>{gripData?.data?.name}</>}
      <Title level={5}>Hands</Title>
      {handsIsLoading ? <>Loading...</> : <>{handsData?.data?.name}</>}
      <Title level={5}>Main Muscle</Title>
      {mainMuscleIsLoading ? (
        <>Loading...</>
      ) : (
        <>{mainMuscleData?.data?.name}</>
      )}
      <Title level={5}>Primary Muscle</Title>
      {primaryMuscleIsLoading ? (
        <>Loading...</>
      ) : (
        <>{primaryMuscleData?.data?.name}</>
      )}
      <Title level={5}>Created At</Title>
      <DateField value={record?.created_at} />
    </Show>
  );
};
