import React from "react";
import { BaseRecord, IResourceComponentsProps, useMany } from "@refinedev/core";
import {
  DateField,
  DeleteButton,
  EditButton,
  ImageField,
  List,
  ShowButton,
  TagField,
  useTable,
} from "@refinedev/antd";
import { Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const ExerciseList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
    sorters: {
      mode: "off",
    },
    pagination: {
      mode: "off",
    },
  });

  const { data: attachmentData, isLoading: attachmentIsLoading } = useMany({
    resource: "attachment",
    ids: tableProps?.dataSource?.map((item) => item?.attachmentId) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  const { data: gripData, isLoading: gripIsLoading } = useMany({
    resource: "grip",
    ids: tableProps?.dataSource?.map((item) => item?.gripId) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  const { data: handsData, isLoading: handsIsLoading } = useMany({
    resource: "hand",
    ids: tableProps?.dataSource?.map((item) => item?.handsId) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  const { data: mainMuscleData, isLoading: mainMuscleIsLoading } = useMany({
    resource: "mainMuscle",
    ids: tableProps?.dataSource?.map((item) => item?.mainMuscleId) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  const { data: primaryMuscleData, isLoading: primaryMuscleIsLoading } =
    useMany({
      resource: "primaryMuscle",
      ids: tableProps?.dataSource?.map((item) => item?.primaryMuscleId) ?? [],
      queryOptions: {
        enabled: !!tableProps?.dataSource,
      },
    });

  const { data: equipmentsData, isLoading: equipmentsIsLoading } = useMany({
    resource: "equipment",
    ids: [].concat(
      ...(tableProps?.dataSource?.map((item) => item?.equipments) ?? [])
    ),
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  const { data: tipsData, isLoading: tipsIsLoading } = useMany({
    resource: "tips",
    ids: [].concat(
      ...(tableProps?.dataSource?.map((item) => item?.tips) ?? [])
    ),
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex="exercise_name"
          title="Exercise Name"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
              <Input
                placeholder="Search Exercise Name"
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </div>
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          )}
          onFilter={(value: any, record: any) =>
            record.exercise_name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          }
          sorter={(a: any, b: any) => {
            return a.exercise_name.localeCompare(b.exercise_name);
          }}
        />
        // embed youtube url
        <Table.Column
          title={"Youtube Video"}
          render={(value: any) => (
            <a href={value} target="_blank" rel="noopener noreferrer">
              VIEW
            </a>
          )}
          dataIndex={"youtube_url"}
        ></Table.Column>
        <Table.Column
          dataIndex="male_thumbnail_url"
          title="Male Thumbnail"
          render={(value: any) => (
            <ImageField style={{ maxWidth: "100px" }} value={value} />
          )}
        />
        <Table.Column
          dataIndex="female_thumbnail_url"
          title="Female Thumbnail"
          render={(value: any) => (
            <ImageField style={{ maxWidth: "100px" }} value={value} />
          )}
        />
        <Table.Column
          dataIndex="male_animation_url"
          title="Male Animation"
          render={(value: any) =>
            value ? (
              <a href={value} target="_blank" rel="noopener noreferrer">
                VIEW
              </a>
            ) : null
          }
        />
        <Table.Column
          dataIndex="female_animation_url"
          title="Female Animation"
          render={(value: any) =>
            value ? (
              <a href={value} target="_blank" rel="noopener noreferrer">
                VIEW
              </a>
            ) : null
          }
        />
        <Table.Column
          dataIndex={["exercise_type", "name"]}
          title={"Exercise Type"}
        />
        <Table.Column
          dataIndex={["gripId"]}
          title="Grip"
          render={(value) =>
            gripIsLoading ? (
              <>Loading...</>
            ) : (
              gripData?.data?.find((item) => item.id === value)?.name
            )
          }
        />
        <Table.Column
          dataIndex={["handsId"]}
          title="Hands"
          render={(value) =>
            handsIsLoading ? (
              <>Loading...</>
            ) : (
              handsData?.data?.find((item) => item.id === value)?.name
            )
          }
        />
        <Table.Column
          dataIndex={["mainMuscleId"]}
          title="Main Muscle"
          render={(value) =>
            mainMuscleIsLoading ? (
              <>Loading...</>
            ) : (
              mainMuscleData?.data?.find((item) => item.id === value)?.name
            )
          }
        />
        <Table.Column
          dataIndex={["primaryMuscleId"]}
          title="Primary Muscle"
          render={(value) =>
            primaryMuscleIsLoading ? (
              <>Loading...</>
            ) : (
              primaryMuscleData?.data?.find((item) => item.id === value)?.name
            )
          }
        />
        <Table.Column
          dataIndex="equipments"
          title="Equipments"
          render={(value: any[]) =>
            equipmentsIsLoading ? (
              <>Loading...</>
            ) : (
              <>
                {value?.map((item, index) => {
                  return <TagField key={index} value={item.name} />;
                })}
              </>
            )
          }
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
