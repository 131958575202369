import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, DatePicker, Select } from "antd";
import dayjs from "dayjs";

export const GroupedDayCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: splitDaysSelectProps } = useSelect({
    resource: "splitDay",
    optionLabel: "dayName",
    optionValue: "id",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Split Days"
          name={"splitDayIds"}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value: any[]) => {
            return {
              value: value?.map((item) => item?.id),
            };
          }}
          getValueFromEvent={(selected: string[]) => {
            return selected?.map((item) => ({ id: item }));
          }}
        >
          <Select mode="multiple" {...splitDaysSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
