import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Upload } from "antd";

export const MealCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: foodsSelectProps } = useSelect({
    resource: "food",
    optionLabel: "name",
    optionValue: "id",
    // onSearch: (value) => {
    //     return {
    //         name: value,
    //     };
    // },
    // filters: {
    //     name: "test",
    // }
  });

  const { selectProps: mealInstructionSelectProps } = useSelect({
    resource: "mealInstruction",
    optionLabel: "text",
    optionValue: "id",
  });

  const { selectProps: mealImageSelectProps } = useSelect({
    resource: "mealImage",
    optionValue: "id",
    optionLabel: "name",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Default Type"
          name={["default_type"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Images">
          <Form.Item
            name="images"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Upload.Dragger
              listType="picture"
              multiple
              beforeUpload={() => false}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Foods"
          name={"foods"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select mode="multiple" {...foodsSelectProps} />
        </Form.Item>
        <Form.Item
          label="Images"
          name={"images"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select mode="multiple" {...mealImageSelectProps} />
        </Form.Item>
        <Form.Item
          label="Instructions"
          name={"instructions"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select mode="multiple" {...mealInstructionSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
