import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Upload, UploadFile } from "antd";

export const PrimaryMuscleEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const primaryMuscleData = queryResult?.data?.data;

  const { selectProps: mainMuscleSelectProps } = useSelect({
    resource: "mainMuscle",
    defaultValue: primaryMuscleData?.mainMuscleId,
    optionLabel: "name",
  });

  const getDefaultFileList = (imageUrls: any[]) => {
    const rtn: UploadFile[] = imageUrls
      ? imageUrls.map((url, index) => ({
          uid: index.toString(),
          name: `Image ${Math.floor(Math.random() * 1000)}`,
          status: "done",
          url,
        }))
      : [];
    return rtn;
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Id"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Male Front Muscle Image">
          <Form.Item
            name="male_front_image_url"
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              defaultFileList={getDefaultFileList(
                primaryMuscleData?.male_front_image_url
              )}
              listType="picture-card"
              action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=mainMuscleImg`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              multiple={true}
              onRemove={(file) => {
                console.log(file);
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Female Front Muscle Image">
          <Form.Item
            name={"female_front_image_url"}
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              listType="picture-card"
              action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=mainMuscleImg`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              multiple={true}
              defaultFileList={getDefaultFileList(
                primaryMuscleData?.female_front_image_url
              )}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Male Back Muscle Image">
          <Form.Item
            name={"male_back_image_url"}
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              listType="picture-card"
              action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=mainMuscleImg`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              multiple={true}
              defaultFileList={getDefaultFileList(
                primaryMuscleData?.male_back_image_url
              )}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Female Back Muscle Image">
          <Form.Item
            name={"female_back_image_url"}
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              listType="picture-card"
              action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=mainMuscleImg`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              multiple={true}
              defaultFileList={getDefaultFileList(
                primaryMuscleData?.female_back_image_url
              )}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Main Muscle"
          name={"mainMuscleId"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...mainMuscleSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
