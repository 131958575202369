import React, { useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { provider } from "../../App";
import { InputNumber } from "antd";

const DummyRpeFrontend: React.FC<IResourceComponentsProps> = () => {
  const [previousWeight, setPreviousWeight] = useState(0);
  const [reps, setReps] = useState(0);
  const [targetRpe, setTargetRpe] = useState(0);

  // const [actualRpe, setActualRpe] = useState(0);
  // const [calculateId, setCalculateId] = useState(0);
  // const [recalculateResult, setRecalculateResult] = useState(0);

  const [calculateResult, setCalculateResult] = useState(0);
  const [oneRepMaxWeight, setOneRepMaxWeight] = useState(0);
  const [oneRepMaxReps, setOneRepMaxReps] = useState(0);
  const [estimated1RM, setEstimated1RM] = useState(0);
  const [weightIncrement, setWeightIncrement] = useState(2.5);
  const [oneRMPercentage, setOneRMPercentage] = useState(0);

  const handleEstimate1RM = async () => {
    try {
      const res = await provider.custom({
        url: `${process.env.REACT_APP_API_URL}/calculation/rpe/generic`,
        method: "post",
        payload: {
          weight: oneRepMaxWeight,
          reps: oneRepMaxReps,
        },
      });
      setEstimated1RM(Number(res.data));
      setPreviousWeight(Number(res.data));
    } catch (error) {
      console.error(error);
      setEstimated1RM(0);
    }
  };

  const handleCalculate = async () => {
    try {
      const res = await provider.custom({
        url: `${process.env.REACT_APP_API_URL}/calculation/rpe/dummy`,
        method: "post",
        payload: {
          prevWeight: previousWeight,
          reps: reps,
          targetRpe: targetRpe,
          weightIncrement: weightIncrement,
        },
      });
      // setCalculateId(Number(res.data.ret.id));
      setCalculateResult(Math.trunc(res.data.targetWeight));
      setOneRMPercentage(res.data.oneRepMaxPercentage);
    } catch (error) {
      console.error(error);
      setCalculateResult(0);
    }
  };

  // const handleRecalculate = async () => {
  //   try {
  //     const res = await provider.custom({
  //       url: `${process.env.REACT_APP_API_URL}/calculation/rpe/${calculateId}`,
  //       method: "put",
  //       payload: {
  //         actualRpe: actualRpe,
  //       },
  //     });
  //     setRecalculateResult(Math.trunc(res.data.newWeight));
  //   } catch (error) {
  //     console.error(error);
  //     setRecalculateResult(0);
  //   }
  // };

  return (
    <div>
      <h1>DummyRpeFrontend</h1>

      <h2>Estimate 1RM</h2>
      <h3>Assuming RPE to be 7.5</h3>
      <div>
        <label htmlFor="oneRepMaxWeight">Weight:</label>
        <InputNumber
          id={"oneRepMaxWeight"}
          value={oneRepMaxWeight}
          onChange={(e) => setOneRepMaxWeight(e!)}
        />
        <br />
        <br />
        <label htmlFor="oneRepMaxReps">Reps:</label>
        <InputNumber
          id={"oneRepMaxReps"}
          value={oneRepMaxReps}
          onChange={(e) => setOneRepMaxReps(e!)}
        />
        <br />
        <br />
        <button onClick={handleEstimate1RM}>Estimate 1RM</button>
        <br />
        <br />
        <div>{estimated1RM}</div>
        <br />
        <br />
      </div>

      <h2>Calculate Target Weight</h2>
      <div>
        <label htmlFor="previousWeight">Previous Weight:</label>
        <InputNumber
          id={"previousWeight"}
          value={previousWeight}
          onChange={(e) => setPreviousWeight(e!)}
        />
        <br />
        <br />
        <label htmlFor="reps">Reps:</label>
        <InputNumber id={"reps"} value={reps} onChange={(e) => setReps(e!)} />
        <br />
        <br />
        <label htmlFor="targetRpe">Target RPE:</label>
        <InputNumber
          id={"targetRpe"}
          value={targetRpe}
          onChange={(e) => setTargetRpe(e!)}
        />
        <br />
        <br />
        <label htmlFor="weightIncrement">Weight Increment:</label>
        <InputNumber
          id={"weightIncrement"}
          value={weightIncrement}
          onChange={(e) => setWeightIncrement(e!)}
        />
        <br />
        <br />
        <button onClick={handleCalculate}>Calculate Target Weight</button>
        <br />
        <br />
        <div>Target Weight: {calculateResult}</div>
        <div>1RM%: {oneRMPercentage}</div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default DummyRpeFrontend;
