import React from "react";
import { BaseKey, BaseRecord, IResourceComponentsProps } from "@refinedev/core";
import {
  DateField,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import { message, Space, Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { provider } from "../../App";

export const BlockList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const exportBlock = async (id: BaseKey | undefined) => {
    const block = await provider.custom({
      url: `${process.env.REACT_APP_API_URL}/block/${id}`,
      method: "get",
    });
    if (block) {
      const blob = new Blob([JSON.stringify(block.data, null, 2)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      // add a human readable timestamp to the filename to prevent caching
      link.download = `${block.data.name}.json`;
      link.click();
    }
  };

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex={["experience_level", "name"]}
          title="Experience Level"
        />
        <Table.Column
          dataIndex="block_length_in_weeks"
          title="Block Length In Weeks"
        />
        <Table.Column
          dataIndex="workout_days_per_week"
          title="Workout Days Per Week"
        />
        <Table.Column
          dataIndex={["created_at"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            // add a download button
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
              <DownloadOutlined
                style={{
                  border: "1px solid gray",
                  padding: "23%",
                  borderRadius: "15%",
                }}
                onClick={() => {
                  exportBlock(record.id);
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
