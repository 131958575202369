import React from "react";
import { BaseRecord, IResourceComponentsProps, useMany } from "@refinedev/core";
import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import { Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const FoodList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
    sorters: {
      mode: "off",
    },
  });

  const { data: mealData, isLoading: mealIsLoading } = useMany({
    resource: "meal",
    ids: tableProps?.dataSource?.map((item) => item?.mealId) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  return (
    <List canCreate={true}>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex="name"
          title="Name"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
              <Input
                placeholder="Search Food Name"
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </div>
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          )}
          onFilter={(value: any, record: any) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase())
          }
        />
        <Table.Column
          dataIndex="brand_name"
          title="Brand Name"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
              <Input
                placeholder="Search Brand Name"
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </div>
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          )}
          onFilter={(value: any, record: any) =>
            record.brand_name.toString().toLowerCase().includes(value.toLowerCase())
          }
        />
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column dataIndex="serving_size" title="Serving Size" />
        <Table.Column
          dataIndex="servings_per_container"
          title="Servings Per Container"
        />
        <Table.Column dataIndex="type" title="Type" />
        <Table.Column dataIndex="energy" title="Energy" />
        <Table.Column dataIndex="energy_unit" title="Energy Unit" />

        <Table.Column dataIndex="protein" title="Protein" />
        <Table.Column dataIndex="fat" title="Fat" />
        <Table.Column dataIndex="carbohydrates" title="Carbohydrates" />
        <Table.Column dataIndex="fiber" title="Fiber" />
        <Table.Column dataIndex="starch" title="Starch" />
        <Table.Column dataIndex="sugars" title="Sugars" />
        <Table.Column dataIndex="added_sugars" title="Added Sugars" />
        <Table.Column dataIndex="net_carbs" title="Net Carbs" />
        <Table.Column dataIndex="cystine" title="Cystine" />
        <Table.Column dataIndex="histidine" title="Histidine" />
        <Table.Column dataIndex="isoleucine" title="Isoleucine" />
        <Table.Column dataIndex="leucine" title="Leucine" />
        <Table.Column dataIndex="lysine" title="Lysine" />
        <Table.Column dataIndex="methionine" title="Methionine" />
        <Table.Column dataIndex="phenylalanine" title="Phenylalanine" />
        <Table.Column dataIndex="threonine" title="Threonine" />
        <Table.Column dataIndex="tryptophan" title="Tryptophan" />
        <Table.Column dataIndex="tyrosine" title="Tyrosine" />
        <Table.Column dataIndex="valine" title="Valine" />
        <Table.Column
          dataIndex="monounsaturated_fat"
          title="Monounsaturated Fat"
        />
        <Table.Column
          dataIndex="polyunsaturated_fat"
          title="Polyunsaturated Fat"
        />
        <Table.Column dataIndex="omega_3" title="Omega 3" />
        <Table.Column dataIndex="omega_3_ALA" title="Omega 3 ALA" />
        <Table.Column dataIndex="omega_3_EPA" title="Omega 3 EPA" />
        <Table.Column dataIndex="omega_3_DHA" title="Omega 3 DHA" />
        <Table.Column dataIndex="omega_6" title="Omega 6" />
        <Table.Column dataIndex="saturated_fat" title="Saturated Fat" />
        <Table.Column dataIndex="trans_fat" title="Trans Fat" />
        <Table.Column dataIndex="b1_thiamine" title="B1 Thiamine" />
        <Table.Column dataIndex="b2_riboflavin" title="B2 Riboflavin" />
        <Table.Column dataIndex="b3_niacin" title="B3 Niacin" />
        <Table.Column
          dataIndex="b5_pantothenic_acid"
          title="B5 Pantothenic Acid"
        />
        <Table.Column dataIndex="b6_pyridoxine" title="B6 Pyridoxine" />
        <Table.Column dataIndex="b12_cobalamin" title="B12 Cobalamin" />
        <Table.Column dataIndex="folate" title="Folate" />
        <Table.Column dataIndex="vitamin_a" title="Vitamin A" />
        <Table.Column dataIndex="vitamin_c" title="Vitamin C" />
        <Table.Column dataIndex="vitamin_d" title="Vitamin D" />
        <Table.Column dataIndex="vitamin_e" title="Vitamin E" />
        <Table.Column dataIndex="vitamin_k" title="Vitamin K" />
        <Table.Column dataIndex="calcium" title="Calcium" />
        <Table.Column dataIndex="copper" title="Copper" />
        <Table.Column dataIndex="iron" title="Iron" />
        <Table.Column dataIndex="magnesium" title="Magnesium" />
        <Table.Column dataIndex="manganese" title="Manganese" />
        <Table.Column dataIndex="phosphorus" title="Phosphorus" />
        <Table.Column dataIndex="potassium" title="Potassium" />
        <Table.Column dataIndex="selenium" title="Selenium" />
        <Table.Column dataIndex="sodium" title="Sodium" />
        <Table.Column dataIndex="zinc" title="Zinc" />
        <Table.Column dataIndex="cholesterol" title="Cholesterol" />
        <Table.Column dataIndex="choline" title="Choline" />
        <Table.Column dataIndex="alcohol" title="Alcohol" />
        <Table.Column dataIndex="caffeine" title="Caffeine" />
        <Table.Column dataIndex="water" title="Water" />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
