import React, { useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  Spin,
  Upload,
} from "antd";
import { provider } from "../../App";
import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { convertNameToId } from "../../utils/misc.utils";

export const BlockCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();
  const [blockSize, setBlockSize] = useState<number>(0);
  const [workoutDays, setWorkoutDays] = useState<number>(0);
  const [step, setStep] = useState<number>(1);
  const [name, setName] = useState<string>("");
  const [experience, setExperience] = useState<number>();
  const [muscleFocus, setMuscleFocus] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const { selectProps: mainMuscleSelectProps } = useSelect({
    resource: "mainMuscle",
    optionLabel: "name",
  });

  const { selectProps: exerciseTypeSelectProps } = useSelect({
    resource: "exerciseType",
    optionLabel: "name",
  });
  const { selectProps: exerciseSelectProps } = useSelect({
    resource: "exercise",
    optionLabel: "exercise_name",
  });
  const { selectProps: primaryMuscleSelectProps } = useSelect({
    resource: "primaryMuscle",
    optionLabel: "name",
  });
  const { selectProps: liftingExperienceSelectProps } = useSelect({
    resource: "liftingExperience",
    optionLabel: "name",
  });

  const handleNextStep = () => {
    if (step === 1) {
      setStep(2);
    }
  };

  const handleFinish = (values: any) => {
    console.log("values: ", values);
    const weeks: any[] = [];

    for (let weekIndex = 0; weekIndex < blockSize; weekIndex++) {
      const weekName = values[`week_${weekIndex + 1}_name`];
      const days: any[] = [];

      for (let dayIndex = 0; dayIndex < workoutDays; dayIndex++) {
        const dayName =
          values[`week_${weekIndex + 1}_day_${dayIndex + 1}_name`];
        const dayImage =
          values[`week_${weekIndex + 1}_day_${dayIndex + 1}_image`];
        const exercises =
          values[`week_${weekIndex + 1}_day_${dayIndex + 1}_exercises`];
        const mainMuscles =
          values[`week_${weekIndex + 1}_day_${dayIndex + 1}_mainMuscles`];

        const exerciseData: any[] =
          exercises?.map((exercise: any) => {
            const { exercise_id, duration, sets, muscle_focus } = exercise; // Added muscle_focus here
            const setDetails =
              sets?.map((set: any) => ({
                rep_target: set.rep_target,
                goal_rpe: set.goal_rpe,
                rest_time: set.rest_time,
                amrap: set.amrap, // Include the amrap value here
              })) || [];
            return {
              exerciseId: exercise_id,
              sets: setDetails,
              duration: parseInt(duration),
              muscle_focus: muscle_focus, // Include it here
            };
          }) || [];

        const mainMusclesData: any[] =
          mainMuscles?.map((muscle: any) => {
            const setDetails =
              muscle.sets?.map((set: any) => ({
                rep_target: set.rep_target,
                goal_rpe: set.goal_rpe,
                rest_time: set.rest_time,
                amrap: set.amrap, // Include the amrap value here
              })) || [];
            return {
              main_muscle_id: muscle.main_muscle_id,
              exercise_type_id: muscle.exercise_type_id,
              primary_muscle_id: muscle.primary_muscle_id,
              sets: setDetails, // Include sets here
            };
          }) || [];

        days.push({
          day_number: dayIndex + 1,
          day_name: dayName,
          day_image: dayImage ? dayImage[0].response.data.url : "",
          exercises: exerciseData,
          main_muscles: mainMusclesData,
        });
      }

      weeks.push({
        week_name: weekName,
        week_number: weekIndex + 1,
        days: days,
      });
    }

    const payload = {
      name,
      liftingExperienceId: experience,
      block_length_in_weeks: blockSize,
      workout_days_per_week: workoutDays,
      muscle_focus_id: muscleFocus,
      weeks,
    };

    // Function to download the payload as a JSON file
    const downloadPayloadAsJson = (data: any) => {
      const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(data));
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "blockPayload.json");
      document.body.appendChild(downloadAnchorNode); // Required for Firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    };

    // Call the download function with the payload
    downloadPayloadAsJson(payload);
    provider
      .create({
        resource: "block",
        variables: payload,
      })
      .then((response) => {
        console.log(response);
        window.location.href = "/block";
      });
  };

  const handleJsonFileImport = (
    e: React.ChangeEvent<HTMLInputElement>,
    formProps: any
  ) => {
    setLoading(true);
    const fileReader = new FileReader();

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = async (e: ProgressEvent<FileReader>) => {
        const content = e.target?.result;

        try {
          const jsonData = JSON.parse(content as string);
          // Assuming jsonData contains fields that correspond directly to the form field names
          await convertNameToId(jsonData);
          let response;
          try {
            response = await provider.create({
              resource: "block",
              variables: jsonData,
            });
          } catch (e) {
            message.error("Error creating block (Invalid file format): " + e);
            console.error("Error creating block: ", e);
          }
          setLoading(false);
          // On success, redirect to the Listblock screen
          if (response) {
            window.location.href = "/block";
          }
        } catch (error) {
          message.error("Error parsing JSON: " + error);
          setLoading(false);
          console.error("Error parsing JSON: ", error);
        }
      };
    } else {
      setLoading(false);
      message.error("No file selected!");
    }
  };

  const renderStep1 = () => {
    return loading ? (
      <div style={{ textAlign: "center" }}>
        <Spin tip="Processing..." />
      </div>
    ) : (
      <Form {...formProps} layout="vertical">
        <Form.Item label="JSON File">
          <input
            type="file"
            accept=".json"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                handleJsonFileImport(e, formProps);
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item
          name={["experience_level"]}
          label={`Experience Level`}
          rules={[{ required: true }]}
        >
          <Select
            onSelect={(value) => {
              setExperience(Number(value));
            }}
            placeholder="Select Lifting Experience"
            {...liftingExperienceSelectProps}
            onSearch={undefined}
            filterOption={true}
            optionFilterProp={"label"}
          />
        </Form.Item>
        <Form.Item
          label="Block Length In Weeks"
          name={["block_length_in_weeks"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            onChange={(e) => setBlockSize(Number(e.target.value))}
          />
        </Form.Item>
        <Form.Item
          label="Workout Days Per Week"
          name={["workout_days_per_week"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            type="number"
            onChange={(e) => setWorkoutDays(Number(e.target.value))}
          />
        </Form.Item>
        <Form.Item name={"muscle_focus"} label={`Muscle Focus`}>
          <Select
            onSelect={(value) => setMuscleFocus(Number(value))}
            placeholder="Select Primary Muscle"
            {...primaryMuscleSelectProps}
            onSearch={undefined}
            filterOption={true}
            optionFilterProp={"label"}
          />
        </Form.Item>
        <Button onClick={handleNextStep}>Next</Button>
      </Form>
    );
  };

  const renderStep2 = () => (
    <Form {...formProps} layout="vertical" onFinish={handleFinish}>
      {Array.from(Array(blockSize).keys()).map((weekIndex) => (
        <div
          key={weekIndex}
          style={{
            border: "1px solid gray",
            margin: "2%",
            borderRadius: "5px",
            padding: "3%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h3 style={{ fontWeight: "bold" }}>Week {weekIndex + 1}</h3>
            </div>
            <Form.Item name={[`week_${weekIndex + 1}_name`]}>
              <Input
                style={{ width: "500px" }}
                disabled={true}
                defaultValue={`Week ${weekIndex + 1}`}
              />
            </Form.Item>
          </div>
          {Array.from(Array(workoutDays).keys()).map((dayIndex) => (
            <div key={dayIndex}>
              <h4>Day {dayIndex + 1}</h4>
              <Form.Item
                label="Day Name"
                name={[`week_${weekIndex + 1}_day_${dayIndex + 1}_name`]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Day Image">
                <Form.Item
                  name={[`week_${weekIndex + 1}_day_${dayIndex + 1}_image`]}
                  valuePropName={"fileList"}
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                  <Upload.Dragger
                    listType="picture-card"
                    action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=dayImage`}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    }}
                    maxCount={1}
                  >
                    <p className="ant-upload-text">
                      Drag & drop a file in this area
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>

              <Form.List
                name={[`week_${weekIndex + 1}_day_${dayIndex + 1}_exercises`]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div
                        key={field.key}
                        style={{
                          width: "50%",
                          border: "1px solid gray",
                          margin: "2%",
                          borderRadius: "5px",
                          padding: "3%",
                        }}
                      >
                        <Form.Item
                          name={[field.name, "exercise_id"]}
                          label={`Exercise ${index + 1}`}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select an Exercise"
                            {...exerciseSelectProps}
                            onSearch={undefined}
                            filterOption={true}
                            optionFilterProp={"label"}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Duration"
                          name={[field.name, "duration"]}
                        >
                          <InputNumber />
                        </Form.Item>
                        <Form.Item
                          label="Muscle Focus"
                          name={[field.name, "muscle_focus"]}
                        >
                          <Select
                            placeholder="Select Muscle Focus"
                            {...primaryMuscleSelectProps}
                            onSearch={undefined}
                            filterOption={true}
                            optionFilterProp={"label"}
                          />
                        </Form.Item>
                        <Form.List name={[field.name, "sets"]}>
                          {(setFields, { add: addSet, remove: removeSet }) => (
                            <>
                              {setFields.map((setField, setIndex) => (
                                <div
                                  key={setField.key}
                                  style={{
                                    width: "50%",
                                    border: "1px solid gray",
                                    margin: "2%",
                                    borderRadius: "5px",
                                    padding: "3%",
                                  }}
                                >
                                  <Form.Item
                                    label="Rep Target"
                                    name={[setField.name, "rep_target"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please input your Rep Target!",
                                      },
                                      {
                                        validator: async (_, value) => {
                                          if (
                                            value !== "AMRAP" &&
                                            (isNaN(value) ||
                                              value < 1 ||
                                              value > 25)
                                          ) {
                                            return Promise.reject(
                                              new Error(
                                                "Warning: Rep Target is outside of the 1-25 target range. It can either be 'AMRAP' or an integer."
                                              )
                                            );
                                          }
                                        },
                                      },
                                    ]}
                                  >
                                    <InputNumber />
                                  </Form.Item>

                                  <Form.Item
                                    label="Goal RPE"
                                    name={[setField.name, "goal_rpe"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input your Goal RPE!",
                                      },
                                      {
                                        validator: async (_, value) => {
                                          if (value < 5 || value > 10) {
                                            // Note: Adjust the message or condition as per your requirements
                                            return Promise.reject(
                                              new Error(
                                                "Warning: Goal RPE is outside of the 5-10 target range"
                                              )
                                            );
                                          }
                                        },
                                      },
                                    ]}
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                  <Form.Item
                                    label="Rest Time"
                                    name={[setField.name, "rest_time"]}
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                  <Form.Item
                                    name={[setField.name, "amrap"]}
                                    valuePropName="checked" // This is important for the Checkbox to work with Form.Item
                                    initialValue={false}
                                  >
                                    <Checkbox>AMRAP</Checkbox>
                                  </Form.Item>
                                  <Button
                                    type="default"
                                    onClick={() => {
                                      // Retrieve the entire exercise object
                                      const exerciseValues =
                                        formProps!.form!.getFieldValue([
                                          `week_${weekIndex + 1}_day_${
                                            dayIndex + 1
                                          }_exercises`,
                                          index,
                                        ]);

                                      // Extract the specific set to be duplicated
                                      const setToDuplicate =
                                        exerciseValues.sets[setIndex];

                                      // Add a new set with the same values
                                      addSet(setToDuplicate);
                                    }}
                                    style={{
                                      marginTop: "5px",
                                      marginRight: "5px",
                                    }}
                                    icon={<CopyOutlined />} // Ant Design Copy Icon
                                  >
                                    Repeat Set
                                  </Button>
                                  <Button
                                    danger
                                    onClick={() => removeSet(setField.name)}
                                    style={{ marginTop: "5px" }}
                                    icon={<DeleteOutlined />}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              ))}

                              <Form.Item>
                                <Button
                                  type="dashed"
                                  style={{
                                    color: "green",
                                  }}
                                  onClick={() => addSet()}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add Set
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                        <Button
                          danger
                          onClick={() => remove(field.name)}
                          style={{ marginTop: "5px" }}
                          icon={<DeleteOutlined />}
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        style={{
                          color: "green",
                        }}
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Exercise
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.List
                name={[`week_${weekIndex + 1}_day_${dayIndex + 1}_mainMuscles`]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div
                        key={field.key}
                        style={{
                          width: "50%",
                          border: "1px solid gray",
                          margin: "2%",
                          borderRadius: "5px",
                          padding: "3%",
                        }}
                      >
                        <Form.Item
                          label={`Main Muscle ${index + 1}`}
                          name={[field.name, "main_muscle_id"]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Main Muscle"
                            {...mainMuscleSelectProps}
                            onSearch={undefined}
                            filterOption={true}
                            optionFilterProp={"label"}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Exercise Type"
                          name={[field.name, "exercise_type_id"]}
                        >
                          <Select
                            placeholder="Select Exercise Type"
                            {...exerciseTypeSelectProps}
                            onSearch={undefined}
                            filterOption={true}
                            optionFilterProp={"label"}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Primary Muscle"
                          name={[field.name, "primary_muscle_id"]}
                        >
                          <Select
                            placeholder="Select Primary Muscle"
                            {...primaryMuscleSelectProps}
                            onSearch={undefined}
                            filterOption={true}
                            optionFilterProp={"label"}
                          />
                        </Form.Item>
                        <Form.List name={[field.name, "sets"]}>
                          {(setFields, { add: addSet, remove: removeSet }) => (
                            <>
                              {setFields.map((setField, setIndex) => (
                                <div
                                  key={setField.key}
                                  style={{
                                    width: "50%",
                                    border: "1px solid gray",
                                    margin: "2%",
                                    borderRadius: "5px",
                                    padding: "3%",
                                  }}
                                >
                                  <Form.Item
                                    label="Rep Target"
                                    name={[setField.name, "rep_target"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input your Goal RPE!",
                                      },
                                      {
                                        validator: async (_, value) => {
                                          if (
                                            value !== "AMRAP" &&
                                            (isNaN(value) ||
                                              value < 1 ||
                                              value > 25)
                                          ) {
                                            return Promise.reject(
                                              new Error(
                                                "Warning: Rep Target is outside of the 1-25 target range. it can either be 'AMRAP' or an integer."
                                              )
                                            );
                                          }
                                        },
                                      },
                                    ]}
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                  <Form.Item
                                    label="Goal RPE"
                                    name={[setField.name, "goal_rpe"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input your Goal RPE!",
                                      },
                                      {
                                        validator: async (_, value) => {
                                          if (value < 5 || value > 10) {
                                            // Note: Adjust the message or condition as per your requirements
                                            return Promise.reject(
                                              new Error(
                                                "Warning: Goal RPE is outside of the 5-10 target range"
                                              )
                                            );
                                          }
                                        },
                                      },
                                    ]}
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                  <Form.Item
                                    label="Rest Time"
                                    name={[setField.name, "rest_time"]}
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                  <Form.Item
                                    name={[setField.name, "amrap"]}
                                    valuePropName="checked" // This is important for the Checkbox to work with Form.Item
                                    initialValue={false}
                                  >
                                    <Checkbox>AMRAP</Checkbox>
                                  </Form.Item>
                                  <Button
                                    type="default"
                                    onClick={() => {
                                      // Retrieve the entire exercise object
                                      const exerciseValues =
                                        formProps!.form!.getFieldValue([
                                          `week_${weekIndex + 1}_day_${
                                            dayIndex + 1
                                          }_exercises`,
                                          index,
                                        ]);

                                      // Extract the specific set to be duplicated
                                      const setToDuplicate =
                                        exerciseValues.sets[setIndex];

                                      // Add a new set with the same values
                                      addSet(setToDuplicate);
                                    }}
                                    style={{
                                      marginTop: "5px",
                                      marginRight: "5px",
                                    }}
                                    icon={<CopyOutlined />} // Ant Design Copy Icon
                                  >
                                    Repeat Set
                                  </Button>
                                  <Button
                                    danger
                                    onClick={() => removeSet(setField.name)}
                                    style={{ marginTop: "5px" }}
                                    icon={<DeleteOutlined />}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              ))}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  style={{ color: "green" }}
                                  onClick={() => addSet()}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add Set
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                        <Button
                          danger
                          onClick={() => remove(field.name)}
                          style={{ marginTop: "5px" }}
                          icon={<DeleteOutlined />}
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{
                          color: "green",
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Main Muscle
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          ))}
        </div>
      ))}
    </Form>
  );

  return (
    <div>
      {step === 1 && renderStep1()}
      {step === 2 && (
        <Create saveButtonProps={saveButtonProps}>{renderStep2()}</Create>
      )}
    </div>
  );
};
