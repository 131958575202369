import { provider } from "../App";

async function getSignedUrl(dir: string) {
    try {
        return await provider
            .custom({
                url: `${process.env.REACT_APP_API_URL}/s3/getUrl`,
                method: "post",
                payload: {
                    dir: dir,
                },
            })
            .then((res) => {
                return res.data.url;
            });
    } catch (error) {
        console.log(error);
    }
}

function cleanUrl(url: string) {
    return url.split("?")[0];
}

export { getSignedUrl, cleanUrl };
