import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { DateField, Show } from "@refinedev/antd";
import { Typography, Card, Tag, Space, Divider } from "antd";

const { Title, Text } = Typography;

export const SplitDayShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Id</Title>
      <Text>{record?.id}</Text>
      <Divider />
      <Title level={5}>Day Name</Title>
      <Text>{record?.dayName}</Text>
      <Divider />
      {record?.muscleEmphasis && (
        <>
          <Title level={5}>Muscle Emphasis</Title>
          <Tag color="blue">{record?.muscleEmphasis.name}</Tag>
          <Divider />
        </>
      )}
      <Title level={5}>Created At</Title>
      <DateField value={record?.createdAt} />
      <Divider />
      <Title level={5}>Updated At</Title>
      <DateField value={record?.updatedAt} />
      <Divider />
      <Title level={5}>Exercises</Title>
      {record?.exercises.map((exercise: any) => (
        <Card key={exercise.id} style={{ marginBottom: 16 }}>
          <Space direction="vertical" size="middle">
            <Space>
              <Text strong>Main Muscle:</Text>
              <Tag color="blue">{exercise.mainMuscle.name}</Tag>
            </Space>
            <Space>
              <Text strong>Secondary Muscles:</Text>
              {exercise.secondaryMuscles.map((muscle: any) => (
                <Tag key={muscle.id} color="purple">
                  {muscle.name}
                </Tag>
              ))}
            </Space>
            {exercise.muscleEmphasisId && (
              <Space>
                <Text strong>Muscle Emphasis:</Text>
                {exercise.muscleEmphasis && (
                  <Tag color="blue">{exercise.muscleEmphasis.name}</Tag>
                )}
              </Space>
            )}
            {exercise.exerciseTypeId && (
              <Space>
                <Text strong>Exercise Type:</Text>
                {exercise.exerciseType && (
                  <Tag color="blue">{exercise.exerciseType.name}</Tag>
                )}
              </Space>
            )}
          </Space>
        </Card>
      ))}
    </Show>
  );
};
