import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, DatePicker, Checkbox, Select } from "antd";
import dayjs from "dayjs";

export const ProgramEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const programData = queryResult?.data?.data;

  const { selectProps: exercisesSelectProps } = useSelect({
    resource: "exercise",
    defaultValue: programData?.exercises,
  });

  const { selectProps: blocksSelectProps } = useSelect({
    resource: "block",
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Id"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Goal Rpe"
          name={["goal_rpe"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Predefined"
          valuePropName="checked"
          name={["predefined"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox>Predefined</Checkbox>
        </Form.Item>
        <Form.Item
          label="Training Days Per Week"
          name={["training_days_per_week"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Blocks"
          name={"blocks"}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value: any[]) => {
            return {
              value: value?.map((item) => item?.name),
            };
          }}
          getValueFromEvent={(selected: string[]) => {
            return selected?.map((item) => ({ name: item }));
          }}
        >
          <Select mode="multiple" {...blocksSelectProps} />
        </Form.Item>
        <Form.Item
          label="Exercises"
          name={"exercises"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select mode="multiple" {...exercisesSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
