import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  ImageField,
} from "@refinedev/antd";
import { Table, Space, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const PrimaryMuscleList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
    pagination: {
      mode: "off",
    },
  });

  const { data: mainMuscleData, isLoading: mainMuscleIsLoading } = useMany({
    resource: "mainMuscle",
    ids: tableProps?.dataSource?.map((item) => item?.mainMuscleId) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex="name"
          title="Name"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
              <Input
                placeholder="Search Primary Muscle"
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </div>
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          )}
          onFilter={(value: any, record: any) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase())
          }
        />
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column
          dataIndex="male_front_image_url"
          title="Male Front Muscle Image"
          render={(images: string[]) =>
            images.map((value) => (
              <ImageField style={{ maxWidth: "30px" }} value={value} />
            ))
          }
        />
        <Table.Column
          dataIndex="female_front_image_url"
          title="Female Front Muscle Image"
          render={(images: string[]) =>
            images.map((value) => (
              <ImageField style={{ maxWidth: "30px" }} value={value} />
            ))
          }
        />
        <Table.Column
          dataIndex="male_back_image_url"
          title="Male Back Muscle Image"
          render={(images: string[]) =>
            images.map((value) => (
              <ImageField style={{ maxWidth: "30px" }} value={value} />
            ))
          }
        />
        <Table.Column
          dataIndex="female_back_image_url"
          title="Female Back Muscle Image"
          render={(images: string[]) =>
            images.map((value) => (
              <ImageField style={{ maxWidth: "30px" }} value={value} />
            ))
          }
        />
        <Table.Column
          dataIndex={["mainMuscleId"]}
          title="Main Muscle"
          render={(value) =>
            mainMuscleIsLoading ? (
              <>Loading...</>
            ) : (
              mainMuscleData?.data?.find((item) => item.id === value)?.name
            )
          }
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <DeleteButton hideText size="small" recordItemId={record.id} />
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
