import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  BooleanField,
  DateField,
  DeleteButton,
  TagField,
} from "@refinedev/antd";
import { Table, Space } from "antd";

export const BlockTemplateList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="displayName" title="Display Name" />
        <Table.Column
          dataIndex={["muscleFocus", "name"]}
          title="Muscle Focus"
        />
        <Table.Column
          dataIndex="workoutDaysPerWeek"
          title="Workout Days Per Week"
        />
        <Table.Column
          dataIndex="days"
          title="Grouped Days"
          render={(days: any[]) => (
            <>
              {days.map((day) => (
                <TagField key={day.id} value={day.groupedSplitDay.name} />
              ))}
            </>
          )}
        />
        <Table.Column
          dataIndex={["createdAt"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={["updatedAt"]}
          title="Updated At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
