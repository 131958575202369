import { HttpError } from "@refinedev/core";
import axios from "axios";

const axiosInstance = axios.create();
axiosInstance.defaults.maxContentLength = Infinity;
axiosInstance.defaults.maxBodyLength = Infinity;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }

    const customError: HttpError = {
      ...error,
      message: error.response?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

export { axiosInstance };
