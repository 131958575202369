import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, DatePicker, Select } from "antd";
import dayjs from "dayjs";

export const GroupedDayEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const groupedDaysData = queryResult?.data?.data;

  const { selectProps: splitDaysSelectProps } = useSelect({
    resource: "splitDay",
    defaultValue: groupedDaysData?.splitDays?.map((item: any) => item?.id),
    optionLabel: "dayName",
    optionValue: "id",
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Id"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Split Days"
          name={"splitDays"}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value: any[]) => {
            return {
              value: value?.map((item) => item?.id),
            };
          }}
          getValueFromEvent={(selected: string[]) => {
            return selected?.map((item) => ({ id: item }));
          }}
        >
          <Select mode="multiple" {...splitDaysSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
