import { provider } from "../App";
import { message } from "antd";

import { cleanUrl, getSignedUrl } from "./getUrl.utils";
import { uploadToPresignedUrl } from "./upload.utils";

interface IdCache {
  [key: string]: number | undefined; // Allow for undefined if that's a possibility
}

// Cache object to store the mappings
const idCache: IdCache = {};

const customUpload = async (url: string, file: File) => {
  try {
    return await uploadToPresignedUrl(url, file);
  } catch (error) {
    console.log(error);
  }
};

const removeFile = (url: string) => {
  // the url is the full url of the image
  try {
    return fetch(url, {
      method: "DELETE",
    });
  } catch (error) {
    console.log(error);
  }
};

export const onImageChange = async ({ file, formProps, fieldName }: any) => {
  if (file) {
    try {
      console.log('form', formProps);
      const url = await getSignedUrl("exerciseAnim");
      const previousFileUrl = formProps.form!.getFieldsValue(fieldName);
      if (previousFileUrl) {
        await removeFile(previousFileUrl);
      }
      const img = await customUpload(url!, file!);
      if (img && img.url) {
        const cleanedUrl = cleanUrl(img.url);
        console.log('cleanedUrl: ', cleanedUrl, fieldName);
        formProps.form!.setFieldsValue({ [fieldName]: cleanedUrl });
        // formProps.form!.setFieldValue(fieldName, cleanedUrl);
      } else {
        console.error('No URL returned from upload:', img);
      }
    } catch (error) {
      console.error(`Error in onImageChange for ${fieldName}:`, error);
    }
  }
};



const getIdFromName = async (
  type: string,
  param: string,
  name: string
): Promise<number> => {
  const cacheKey: string = `${type}-${name}`;

  // Return from cache if present
  if (idCache[cacheKey] !== undefined) {
    return idCache[cacheKey] as number; // We can assert this as number because we check for undefined
  }

  // If not in cache, make the API call and store the result in cache
  const response = await provider.custom({
    url: `${
      process.env.REACT_APP_API_URL
    }/search/${type}?${param}=${encodeURIComponent(name)}`,
    method: "get",
    headers: {
      "x-api-key": "123SearchToken123",
    },
  });

  const id = response.data.id;

  // Ensure that id is a number before proceeding
  if (typeof id === "number") {
    idCache[cacheKey] = id;
    return id;
  } else {
    throw new Error("ID from response is not a number");
  }
};

export const convertNameToId = async (block: any) => {
  // check if block's week size and day number is valid
  if (block.weeks.length !== block.block_length_in_weeks) {
    message.error(
      `The number of weeks in the block is not equal to the block length in weeks.`
    );
  }

  // check workout days per week
  for (const week of block.weeks) {
    if (week.days.length !== block.workout_days_per_week) {
      message.error(
        `The number of days in the week is not equal to the workout days per week.`
      );
    }
  }

  block.liftingExperienceId = await getIdFromName(
    "liftingExperience",
    "lifting_experience_name",
    block.liftingExperienceId.toString()
  );
  block.muscle_focus_id = await getIdFromName(
    "primaryMuscle",
    "primary_muscle_name",
    block.muscle_focus_id.toString()
  );

  for (const week of block.weeks) {
    for (const day of week.days) {
      if (day.exercises) {
        for (const exercise of day.exercises) {
          exercise.exerciseId = await getIdFromName(
            "exercise",
            "exercise_name",
            exercise.exerciseId.toString()
          );
        }
      }
      if (day.main_muscles) {
        for (const mainMuscle of day.main_muscles) {
          mainMuscle.main_muscle_id = await getIdFromName(
            "mainMuscle",
            "main_muscle_name",
            mainMuscle.main_muscle_id.toString()
          );
          mainMuscle.exercise_type_id = await getIdFromName(
            "exerciseType",
            "exercise_type_name",
            mainMuscle.exercise_type_id.toString()
          );
          mainMuscle.primary_muscle_id = await getIdFromName(
            "primaryMuscle",
            "primary_muscle_name",
            mainMuscle.primary_muscle_id.toString()
          );
        }
      }
    }
  }
};
