import React, { useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, InputNumber, Select, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { onImageChange } from "../../utils/misc.utils";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

export const ExerciseCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState("");

  const handleEditorChange = (newValue: string | undefined) => {
    if (newValue !== undefined) {
      setValue(newValue);
      // Update the form value for the content field
      formProps.form!.setFieldsValue({
        exerciseGuide: newValue,
      });
    }
  };

  const { selectProps: attachmentSelectProps } = useSelect({
    resource: "attachment",
    optionLabel: "name",
  });

  const { selectProps: gripSelectProps } = useSelect({
    resource: "grip",
    optionLabel: "name",
  });

  const { selectProps: handsSelectProps } = useSelect({
    resource: "hand",
    optionLabel: "name",
  });

  const { selectProps: mainMuscleSelectProps } = useSelect({
    resource: "mainMuscle",
    optionLabel: "name",
  });

  const { selectProps: primaryMuscleSelectProps } = useSelect({
    resource: "primaryMuscle",
    optionLabel: "name",
  });

  const { selectProps: equipmentsSelectProps } = useSelect({
    resource: "equipment",
    optionLabel: "name",
  });

  const { selectProps: exerciseTypeSelectProps } = useSelect({
    resource: "exerciseType",
    optionLabel: "name",
  });

  const { selectProps: gripWidthSelectProps } = useSelect({
    resource: "gripWidth",
    optionLabel: "name",
  });
  const { selectProps: tipsSelectProps } = useSelect({
    resource: "tips",
    optionLabel: "tip",
  });

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={loading}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Exercise Name"
          name={["exercise_name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Image">
          <Form.Item
            name={"image"}
            valuePropName={"fileList"}
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              listType="picture-card"
              action={`${process.env.REACT_APP_API_URL}/s3/uploadFile?dir=exerciseImages`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              maxCount={1}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Description" name={["description"]}>
          <TextArea size={"middle"} />
        </Form.Item>
        <Form.Item label="Duration in Minutes" name={["duration"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Youtube URL" name={["youtube_url"]}>
          <Input />
        </Form.Item>
        <Form.Item label="Male Animation" name={["male_animation_url"]}>
          <Upload.Dragger
            listType="picture-card"
            onChange={async (info) => {
              setLoading(true);
              await onImageChange({
                file: info.file,
                formProps,
                fieldName: "male_animation_url",
              });
              setLoading(false);
            }}
            beforeUpload={() => false}
            onRemove={() => {
              formProps.form!.setFieldsValue({ male_animation_url: "" });
              // TODO: remove image from s3
            }}
          >
            <p className="ant-upload-text">Drag & drop a file in this area</p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item label="Female Animation" name="female_animation_url">
          <Upload.Dragger
            listType="picture-card"
            onChange={async (info) => {
              setLoading(true);
              await onImageChange({
                file: info.file,
                formProps,
                fieldName: "female_animation_url",
              });
              setLoading(false);
            }}
            beforeUpload={() => false}
            onRemove={() => {
              formProps.form!.setFieldsValue({ female_animation_url: "" });
              // TODO: remove image from s3
            }}
          >
            <p className="ant-upload-text">Drag & drop a file in this area</p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item label="Male Thumbnail" name={["male_thumbnail_url"]}>
          <Upload.Dragger
            listType="picture-card"
            onChange={async (info) => {
              setLoading(true);
              await onImageChange({
                file: info.file,
                formProps,
                fieldName: "male_thumbnail_url",
              });
              setLoading(false);
            }}
            beforeUpload={() => false}
            onRemove={() => {
              formProps.form!.setFieldsValue({ male_thumbnail_url: "" });
              // TODO: remove image from s3
            }}
          >
            <p className="ant-upload-text">Drag & drop a file in this area</p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item label="Female Thumbnail" name={["female_thumbnail_url"]}>
          <Upload.Dragger
            listType="picture-card"
            onChange={async (info) => {
              setLoading(true);
              await onImageChange({
                file: info.file,
                formProps,
                fieldName: "female_thumbnail_url",
              });
              setLoading(false);
            }}
            beforeUpload={() => false}
            onRemove={() => {
              formProps.form!.setFieldsValue({ female_thumbnail_url: "" });
              // TODO: remove image from s3
            }}
          >
            <p className="ant-upload-text">Drag & drop a file in this area</p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item
          label="Instructions"
          name={["instructions"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea size={"middle"} />
        </Form.Item>
        <Form.Item
          label="Main Muscle"
          name={["mainMuscleId", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...mainMuscleSelectProps} />
        </Form.Item>
        <Form.Item label={"Coach Comments"} name={["coachComments"]}>
          <TextArea
            placeholder={"Coach Comments"}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item label={"Exercise Guide"} name={["exerciseGuide"]}>
          <MDEditor
            data-color-mode="light"
            value={value}
            onChange={handleEditorChange}
            height={500}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
          />{" "}
        </Form.Item>
        <Form.Item label={"Cautions"} name={["cautions"]}>
          <TextArea
            placeholder={"Cautions"}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item label="Movement" name={["movement"]}>
          <Input />
        </Form.Item>
        <Form.Item label="Attachment" name={["attachmentId", "id"]}>
          <Select {...attachmentSelectProps} />
        </Form.Item>
        <Form.Item label="Grip" name={["gripId", "id"]}>
          <Select {...gripSelectProps} />
        </Form.Item>
        <Form.Item label="Hands" name={["handsId", "id"]}>
          <Select {...handsSelectProps} />
        </Form.Item>
        <Form.Item label="Primary Muscle" name={["primaryMuscleId", "id"]}>
          <Select {...primaryMuscleSelectProps} />
        </Form.Item>
        <Form.Item label="Equipments" name={["equipments", "id"]}>
          <Select mode="multiple" {...equipmentsSelectProps} />
        </Form.Item>
        <Form.Item label="Exercise Type" name={["exercise_type", "id"]}>
          <Select {...exerciseTypeSelectProps} />
        </Form.Item>
        <Form.Item label="Grip Width" name={["grip_width", "id"]}>
          <Select {...gripWidthSelectProps} />
        </Form.Item>
        <Form.Item label="Secondary Muscle" name={["secondaryMuscle", "id"]}>
          <Select {...primaryMuscleSelectProps} mode={"multiple"} />
        </Form.Item>
        <Form.Item label="Supporting Muscle" name={["supportingMuscle", "id"]}>
          <Select {...primaryMuscleSelectProps} mode={"multiple"} />
        </Form.Item>
        <Form.Item label="Tips" name={["tips", "id"]}>
          <Select mode={"multiple"} {...tipsSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
