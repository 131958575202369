import React, { useEffect } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Button, Card, Form, Input, InputNumber, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export const SplitDayEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const splitDayData = queryResult?.data?.data;

  const { selectProps: primaryMuscleSelectProps } = useSelect({
    resource: "primaryMuscle",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: mainMuscleSelectProps } = useSelect({
    resource: "mainMuscle",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: exerciseTypeSelectProps } = useSelect({
    resource: "exerciseType",
    optionLabel: "name",
    optionValue: "id",
  });

  useEffect(() => {
    if (splitDayData) {
      formProps.form!.setFieldsValue({
        exercises: splitDayData.exercises.map((exercise: any) => ({
          ...exercise,
          secondaryMuscles: exercise.secondaryMuscles.map(
            (muscle: any) => muscle.id
          ),
        })),
      });
    }
  }, [splitDayData, formProps.form]);

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Id"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Day Name"
          name={["dayName"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={["displayName"]}>
          <Input placeholder={"Display Name"} />
        </Form.Item>
        <Form.Item name={["muscleEmphasisId"]}>
          <Select
            allowClear
            placeholder="Select Muscle Emphasis"
            {...primaryMuscleSelectProps}
            onSearch={undefined}
            filterOption={true}
            optionFilterProp={"label"}
          />
        </Form.Item>
        <Form.List name={["exercises"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Card
                  key={key}
                  title={`Exercise ${name + 1}`}
                  bordered={true}
                  style={{ marginTop: "16px", marginBottom: "10px" }}
                >
                  <Form.Item
                    {...restField}
                    name={[name, "noOfSets"]}
                    fieldKey={[fieldKey!, "noOfSets"]}
                    rules={[{ required: true, message: "Required" }]}
                    style={{ width: "100%" }} // Ensuring the Form.Item covers full width
                    label="Number of Sets" // Add label
                  >
                    <InputNumber
                      placeholder={"Number of Sets"}
                      defaultValue={3}
                      style={{ width: "100%" }} // Ensuring the InputNumber covers full width
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "mainMuscleId"]}
                    fieldKey={[fieldKey!, "mainMuscleId"]}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      placeholder="Select Main Muscle"
                      {...mainMuscleSelectProps}
                      onSearch={undefined}
                      filterOption={true}
                      optionFilterProp={"label"}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "secondaryMuscles"]}
                    fieldKey={[fieldKey!, "secondaryMuscles"]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Primary Muscles"
                      {...primaryMuscleSelectProps}
                      onSearch={undefined}
                      filterOption={true}
                      optionFilterProp={"label"}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "exerciseTypeId"]}
                    fieldKey={[fieldKey!, "exerciseTypeId"]}
                  >
                    <Select
                      allowClear
                      placeholder="Select Exercise Type"
                      {...exerciseTypeSelectProps}
                      onSearch={undefined}
                      filterOption={true}
                      optionFilterProp={"label"}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "muscleEmphasisId"]}
                    fieldKey={[fieldKey!, "muscleEmphasisId"]}
                  >
                    <Select
                      allowClear
                      placeholder="Select Muscle Emphasis"
                      {...mainMuscleSelectProps}
                      onSearch={undefined}
                      filterOption={true}
                      optionFilterProp={"label"}
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Card>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Exercise
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Edit>
  );
};
