import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {useTable, List, EditButton, ShowButton, DeleteButton} from "@refinedev/antd";
import { Table, Space } from "antd";

export const TipList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { data: tipData, isLoading: tipIsLoading } = useMany({
    resource: "tips",
    ids: tableProps?.dataSource?.map((item) => item?.tip) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  const { data: exerciseData, isLoading: exerciseIsLoading } = useMany({
    resource: "exercise",
    ids: tableProps?.dataSource?.map((item) => item?.exerciseId) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex={["tip"]} title="Tip" />
        <Table.Column
          dataIndex={["exerciseId"]}
          title="Exercise"
          // render={(value) =>
          //   exerciseIsLoading ? (
          //     <>Loading...</>
          //   ) : (
          //     exerciseData?.data?.find((item) => item.id === value)
          //   )
          // }
        />

        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <DeleteButton hideText size="small" recordItemId={record.id} />
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
